import cc from 'classcat';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../stores';
import styles from './styles.module.scss';

const StatusBar = () => {
  const {
    globalStore: { statusBar, setStatusBar },
  } = useStore();
  const { t } = useTranslation(['ErrorMessages']);

  return (
    <div
      className={cc([
        styles.statusBar,
        { [styles.active]: statusBar.isVisible },
      ])}
    >
      <span>{t(statusBar.message)}</span>
      <button
        className={cc([
          styles.closeButton,
          {
            [styles.buttonActive]: statusBar.isVisible,
          },
        ])}
        onClick={() => {
          setStatusBar(false, '');
        }}
      />
    </div>
  );
};

export default observer(StatusBar);
