import EventTypes from '../EventTypes';
import { AnalyticsBlock } from '../models';

declare global {
  interface Window {
    __analytics_queue__?: Array<{ id: string; data: any }>;
    fireTag?: (event: string, data: any) => void;
  }
}

function fireOrQueueTag(id: string, data: any) {
  if (typeof window === 'undefined') {
    return;
  }

  if (
    typeof window.fireTag === 'function' &&
    (!window.__analytics_queue__ || window.__analytics_queue__?.length === 0)
  ) {
    window.fireTag(id, data);
    return;
  }

  if (window.__analytics_queue__ && Array.isArray(window.__analytics_queue__)) {
    window.__analytics_queue__.push({ id, data });
  }
}

export default function triggerEvent(event: string, block: AnalyticsBlock) {
  let fireEvent = event;

  if (
    event === EventTypes.Click &&
    block &&
    block.metrics &&
    block.metrics.length
  ) {
    fireEvent = block.metrics;
  }

  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed('Analytics Event:', fireEvent);
    console.table ? console.table(block) : console.log(block);
    console.groupEnd();
  }

  fireOrQueueTag(fireEvent, block);
}
