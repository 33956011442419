import axios from 'axios';

import { SlpData } from '../models/Slp';

export const slpSubmit = (data: SlpData) => {
  const url = `${process.env.REACT_APP_SLP_API}/leads`;
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_SLP_API_KEY}`,
    },
  });
};
