import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';

import Cta from '../../../../components/Cta';
import { useStore } from '../../../../stores';
import { Offer } from '../../../../models/Offer';
import useTrigger from '../../../../analytics/useTrigger';
import EventTypes from '../../../../analytics/EventTypes';
import { getLinkBlock, getOfferBlock } from '../../../../analytics/blocks';
import OfferDetailModal from '../../OfferDetailModal';
import { getOfferLabel } from '../../../../utils/getOfferLabel';
import styles from './styles.module.scss';

const Offers: FC = () => {
  const trigger = useTrigger();
  const {
    estimatorStore: {
      offerClick,
      selectedEstimatorType,
      visibleOffers,
      isKmPeStartTriggered,
      setIsKmPeStartTriggered,
    },
  } = useStore();

  const [selectedOffer, setSelectedOffer] = useState<Offer | undefined>(
    undefined
  );

  const analyticsHandler = (offer: Offer) => {
    trigger(EventTypes.Click, [
      getLinkBlock({
        href: '',
        text: 'See Offer Details',
      }),
      getOfferBlock(offer),
    ]);
  };

  const offerClickHandler = (offer: Offer) => {
    trigger(EventTypes.Click, [
      getLinkBlock({
        href: '',
        text: offer.title,
        metrics: !isKmPeStartTriggered ? 'km-pe-start' : undefined,
      }),
    ]);

    setIsKmPeStartTriggered();
    offerClick(offer.offerId);
  };

  return visibleOffers.length > 0 ? (
    <div className={styles.offerDetailsWrapper}>
      {visibleOffers.map(
        offer =>
          selectedEstimatorType === 'finance' && (
            <div className={styles.checkboxWrapper} key={offer.offerId}>
              <div
                className={cc([
                  styles.checkMark,
                  { [styles.active]: offer.isSelected },
                  { [styles.disabled]: offer.isDisabled },
                ])}
                onClick={() => !offer.isDisabled && offerClickHandler(offer)}
              />
              <div className={styles.checkboxLabel}>
                <p className={styles.checkboxLabelText}>
                  {getOfferLabel(offer)}
                </p>
                <Cta
                  className={cc([
                    styles.cta,
                    { [styles.disabled]: offer.isDisabled },
                  ])}
                  isSecondary
                  onClick={() => {
                    !offer.isDisabled && setSelectedOffer(offer);
                    !offer.isDisabled && analyticsHandler(offer);
                  }}
                >
                  See Offer Details
                </Cta>
              </div>
            </div>
          )
      )}
      <OfferDetailModal
        isOpen={!!selectedOffer}
        onClose={() => setSelectedOffer(undefined)}
        offer={selectedOffer}
      />
    </div>
  ) : (
    <></>
  );
};
export default observer(Offers);
