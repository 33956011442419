import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsContext from '../../../analytics/AnalyticsContext';
import {
  getDealerBlock,
  getLinkBlock,
  getListItemBlock,
} from '../../../analytics/blocks';
import EventTypes from '../../../analytics/EventTypes';
import useTrigger from '../../../analytics/useTrigger';

import Cta from '../../../components/Cta';
import { useStore } from '../../../stores';
import { changePageWithParams } from '../../../utils/changePageWithParams';
import SingleDealer from './SingleDealer';
import styles from './styles.module.scss';

const DealersResult: FC = () => {
  const trigger = useTrigger();
  const {
    dealerStore: { dealers, visibleDealers, selectedDealer, showMoreDealers },
  } = useStore();

  const { t } = useTranslation('Ctas');

  const goToVehicleSelect = () => {
    changePageWithParams('vehicleSelect', { dealerCode: selectedDealer.code });
  };

  const viewInvetoryAnalyticsHandler = () => {
    trigger(EventTypes.Click, [
      getLinkBlock({
        href: '/vehicleSelect',
        text: 'View Inventory',
      }),
      selectedDealer && getDealerBlock(selectedDealer),
    ]);
  };

  const viewMoreDealerAnalyticsHandler = () => {
    trigger(EventTypes.Click, [
      getLinkBlock({
        href: '',
        text: 'View More Dealers',
      }),
    ]);
  };

  return (
    <>
      {visibleDealers.map((dealer, index) => (
        <AnalyticsContext key={dealer.code} blocks={[getListItemBlock(index)]}>
          <SingleDealer
            dealer={dealer}
            isSelected={selectedDealer === dealer}
          />
        </AnalyticsContext>
      ))}

      <div className={styles.moreDealersBtnWrapper}>
        {visibleDealers.length !== dealers.length && dealers.length !== 0 && (
          <Cta
            isTransparent
            arrowVariant="downArrow"
            onClick={() => {
              viewMoreDealerAnalyticsHandler();
              showMoreDealers();
            }}
          >
            {t('viewMoreDealers')}
          </Cta>
        )}
      </div>
      {dealers.length !== 0 && (
        <div className={styles.ctaWrapper}>
          <Cta
            onClick={() => {
              viewInvetoryAnalyticsHandler();
              goToVehicleSelect();
            }}
            isPrimary
          >
            {t('viewInvetory')}
          </Cta>
        </div>
      )}
    </>
  );
};

export default observer(DealersResult);
