import axios from 'axios';

import { DealerServiceParams } from '../models/Dealer';

const url = `${process.env.REACT_APP_DIS_API_URL}/disrest/getDealers/v2`;

const params: DealerServiceParams = {
  brandId: 1,
  proximityMode: 'expandingRadius',
  resultsFormat: 'json',
  resultsMax: 10,
  resultsMode: 'full',
  searchMode: 'pmaProximityLayered',
};

export async function getDealers(zipcode: string) {
  return axios.get(url, {
    headers: { 'x-api-key': process.env.REACT_APP_DIS_API_KEY },
    params: { ...params, zipcode },
  });
}
