const $document: Document = document;

let source = window.parent !== window ? window.parent : undefined;
let origin = '*';

export const initialize = () => {
  const isInitialized = 'message' in window;

  if (!isInitialized) {
    window.addEventListener('message', postMessageHandler, false);
    window.onresize = () => {
      sendHeight();
    };
  }
};

const getDocumentHeight = () => {
  const body = $document.body;
  const html = $document.documentElement;

  const height = Math.max(body.offsetHeight, html.offsetHeight);

  return height;
};

export const sendHeight = () => {
  const height: number = getDocumentHeight();

  if (source && origin) {
    const message = {
      iframeHeight: height.toString(),
    };

    if (source) {
      source.postMessage(JSON.stringify(message), origin);
    }
  }
};

const parseEventData = (data: any) => {
  let dataobj;
  try {
    dataobj = JSON.parse(data);
  } catch (error) {
    console.log(' •• PMH error •• ', dataobj);
  }
  return dataobj;
};

const init = (event: any) => {
  source = event.source;
  origin = event.origin;

  sendHeight();
};

const postMessageHandler = (event: MessageEvent) => {
  const dataobj = parseEventData(event.data);

  if (dataobj === undefined) {
    return;
  }

  switch (dataobj.method) {
    case 'init': {
      init(event);
      break;
    }
    case 'getHeight': {
      sendHeight();
      break;
    }
  }
};
