import React, { ChangeEvent } from 'react';

import cc from 'classcat';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  error?: boolean;
  id?: string;
  isDisabled?: boolean;
  isSecondary?: boolean;
  maxLength?: number;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  prefix?: string;
  value: string | number;
  required?: boolean;
}

const TextInput: React.FC<Props> = ({
  className,
  error = false,
  id,
  isDisabled = false,
  isSecondary = false,
  maxLength,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  type = 'text',
  value,
  prefix,
  required = false,
}) => {
  return (
    <div
      className={cc([
        styles.inputWrapper,
        className,
        { [styles.invalid]: error },
      ])}
    >
      {prefix && <span className={styles.prefix}>$</span>}
      <input
        className={cc([
          styles.input,
          {
            [styles.disabled]: isDisabled,
            [styles.secondary]: isSecondary,
          },
        ])}
        id={id}
        maxLength={maxLength}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        type={type}
        value={value}
        required={required}
      />
    </div>
  );
};

export default TextInput;
