import React from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../stores';
import styles from './styles.module.scss';

const Loader: React.FC = () => {
  const {
    globalStore: { isFetching, isFetchingCalculation },
  } = useStore();

  return (
    <>
      {(isFetching || isFetchingCalculation) && (
        <>
          <div className={styles.overlay} />
          <div className={styles.content}>
            <p>Loading...</p>
          </div>
        </>
      )}
    </>
  );
};

export default observer(Loader);
