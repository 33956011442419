import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './lang-en.json';
import es from './lang-es.json';

const resources = { en, es };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources,
      keySeparator: '.',
      fallbackLng: 'en',
      lng: 'en',
      ns: resources ? Object.keys(resources) : [],
      defaultNS: 'Common',
      interpolation: {
        escapeValue: false,
      },
    },
    (err, t) => {
      if (err) {
        console.log('i18n. something went wrong loading', err);
      } else {
        t('key'); // -> same as i18next.t
      }
    }
  );

export const changeLanguage = (lang: 'es' | 'en') => i18n.changeLanguage(lang);

export default i18n;
