import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';

import {
  getDealerBlock,
  getEstimatorBlock,
  getGlobalBlock,
  getLinkBlock,
  getSectionBlock,
  getSelectedOffersBlock,
  getVehicleBlock,
} from '../../../analytics/blocks';
import EventTypes from '../../../analytics/EventTypes';
import useTrigger from '../../../analytics/useTrigger';
import SelectInput from '../../../components/SelectInput';
import { Option } from '../../../models/Option';
import { useStore } from '../../../stores';
import { changePageWithParams } from '../../../utils/changePageWithParams';
import { getNumberWithComa } from '../../../utils/getNumberWithComa';
import { getQueryParams } from '../../../utils/getQueryParams';
import styles from './styles.module.scss';

interface SelectedVehicleDetailsProps {
  openDisclaimerModal: () => void;
}

const SelectedVehicleDetails: FC<SelectedVehicleDetailsProps> = props => {
  const { openDisclaimerModal } = props;

  const trigger = useTrigger();

  const {
    estimatorStore: { getCalculation, financialParams, visibleOffers },
    vehicleStore: { selectedVehicles, setSelectedVehicle, selectedVehicle },
    globalStore: { setStatusBar, zipcode, tdaCode, onlySelectedVin },
    dealerStore: { selectedDealer },
  } = useStore();

  const vehicleOptions = useMemo(() => {
    return selectedVehicles.map(vehicle => ({
      label: `${vehicle.year} ${vehicle.model} ${vehicle.description}`,
      value: vehicle,
    }));
  }, [selectedVehicles]);

  const onSelect = (option: Option) => {
    setSelectedVehicle(option.value);
    changePageWithParams(
      '/estimator',
      { ...getQueryParams(), vin: option.value.vin },
      true
    );

    const selectedOffers = visibleOffers.filter(offer => offer.isSelected);

    trigger(EventTypes.Click, [
      {
        ...getSectionBlock('payment estimator form'),
        ...getGlobalBlock(zipcode, tdaCode),
        ...getDealerBlock(selectedDealer),
        ...getSelectedOffersBlock(selectedOffers),
        ...getLinkBlock({ href: '', text: option.label }),
        ...(selectedVehicle ? getVehicleBlock(selectedVehicle) : {}),
        ...getEstimatorBlock(financialParams),
      },
    ]);
    if (
      option.value &&
      option.value?.price >
        Number(financialParams.downPayment.replace(/[^\d]/gm, ''))
    ) {
      setStatusBar(false, '');
      getCalculation();
    } else {
      setStatusBar(true, 'Invalid input');
    }
  };

  return (
    <div className={styles.detailsWrapper}>
      <div className={styles.item}>
        {vehicleOptions.length > 0 &&
          vehicleOptions.length !== 1 &&
          !onlySelectedVin && (
            <SelectInput
              value={
                selectedVehicle
                  ? {
                      label: `${selectedVehicle?.year} ${selectedVehicle?.model} ${selectedVehicle?.description}`,
                      value: selectedVehicle,
                    }
                  : {
                      label: '',
                      value: '',
                    }
              }
              onClick={onSelect}
              options={vehicleOptions}
              isSecondary
            />
          )}
        {((vehicleOptions.length > 0 && vehicleOptions.length === 1) ||
          onlySelectedVin) && (
          <div>
            {selectedVehicle?.year} {selectedVehicle?.model}{' '}
            {selectedVehicle?.description}
          </div>
        )}
      </div>
      <div className={styles.item}>VIN#:{selectedVehicle?.vin}</div>
      <div className={styles.item}>Mileage: {selectedVehicle?.miles}</div>
      <div className={styles.item}>
        <label>
          $
          {getNumberWithComa(
            Math.round(selectedVehicle?.price || 0).toString()
          )}{' '}
          Total SRP
          <sup onClick={openDisclaimerModal}>6</sup>
        </label>
      </div>
    </div>
  );
};
export default observer(SelectedVehicleDetails);
