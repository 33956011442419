import cc from 'classcat';
import { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';

import styles from './styles.module.scss';

export interface ModalProps {
  children?: ReactNode;
  className?: string;
  isOpen: boolean;
  onClose?: () => void;
}

const Modal = ({
  children,
  className,
  isOpen,
  onClose = () => {},
}: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overlayIsOpen');
    }
    return () => {
      document.body.classList.remove('overlayIsOpen');
    };
  });

  const ModalContent = (
    <section className={styles.modal}>
      <div className={styles.modalContainer}>
        <div className={styles.modalDialog}>
          <div
            className={styles.overlay}
            onClick={onClose}
            role="presentation"
          />
          <div className={cc([className, styles.dialog])}>
            <div className={styles.content}>
              <div className={styles.closeBtnWrapper}>
                <button className={styles.closeBtn} onClick={onClose} />
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  return isOpen ? ReactDOM.createPortal(ModalContent, document.body) : null;
};

export default Modal;
