import { SlpSelectedTrim, SlpFormData, SlpData } from '../models/Slp';
import { slpSubmit } from '../services/ContactFormService';
import { getCampaignCode } from './getCampaignCode';
import { getOmnitureID } from './getOmnitureID';
import { transformSerieName } from './transformSerieName';

export const submitContactDealerForm = (
  formData: SlpFormData,
  selectedTrim: SlpSelectedTrim
) => {
  const data = createJSONFromForm(formData, selectedTrim);

  return slpSubmit(data);
};

export const createJSONFromForm = (
  request: SlpFormData,
  vehicle: SlpSelectedTrim
) => {
  const {
    firstname,
    lastname,
    dealer,
    preference,
    email,
    phone,
    isMobile,
    comments,
  } = request;

  const campaignCode = getCampaignCode(isMobile);
  const omnitureID = getOmnitureID();

  const format = isMobile ? 'Mobile' : 'Website';
  const requestDate = new Date();

  let json: SlpData = {
    adf: {
      prospect: {
        id: {
          value: '-2',
          sequence: '1',
          source: 'Toyota',
        },
        requestdate: requestDate,
        vehicle: {
          interest: 'buy',
          status: 'used-certified',
          year: vehicle.year,
          make: 'Toyota',
          model: transformSerieName(vehicle.model),
          trim: vehicle.trim,
        },
        customer: {
          comments,
          contact: {
            name: [
              {
                value: firstname,
                part: 'first',
                type: 'individual',
              },
              {
                value: lastname,
                part: 'last',
                type: 'individual',
              },
            ],
          },
        },
        vendor: {
          id: {
            value: dealer.code,
            sequence: '1',
            source: 'TMS Dealer Code',
          },
          vendorname: dealer.name,
          url: dealer.contact?.website ?? '',
          name: [
            {
              value: dealer.contact?.firstName ?? '',
              part: 'first',
              type: 'individual',
            },
            {
              value: dealer.contact?.lastName ?? '',
              part: 'last',
              type: 'individual',
            },
          ],
          email: {
            value: dealer.contact?.email ?? '',
            preferredcontact: '0',
          },
          phone: {
            value: dealer.contact?.phone ?? '',
            preferredcontact: '1',
            type: 'voice',
            time: 'nopreference',
          },
          address: {
            street: {
              line: '1',
              value: dealer.contact?.street ?? '',
            },
            city: dealer.contact?.city ?? '',
            regioncode: dealer.contact?.region ?? '',
            postalcode: dealer.contact?.zip ?? '',
          },
        },
        provider: {
          id: [
            {
              sequence: '1',
              source: 'Toyota',
            },
            {
              value: omnitureID,
              source: 'Omniture_ID',
            },
          ],
          name: format,
          service: campaignCode,
        },
      },
    },
  };

  if (preference === 'email') {
    json.adf.prospect.customer.contact.email = {
      value: email,
      preferredcontact: '0',
    };
  } else if (preference === 'phone') {
    json.adf.prospect.customer.contact.phone = {
      value: phone,
      preferredcontact: '1',
    };
  }

  return json;
};
