import { FC } from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';
import { useTranslation } from 'react-i18next';

import { Dealer } from '../../../../models/Dealer';
import { useStore } from '../../../../stores';
import styles from './styles.module.scss';
import useTrigger from '../../../../analytics/useTrigger';
import { getLinkBlock, getDealerBlock } from '../../../../analytics/blocks';
import EventTypes from '../../../../analytics/EventTypes';

interface SingleDealerProps {
  dealer: Dealer;
  isSelected: boolean;
}

const SingleDealer: FC<SingleDealerProps> = ({ dealer, isSelected }) => {
  const {
    dealerStore: { setSelectedDealer, selectedDealer },
  } = useStore();

  const trigger = useTrigger();
  const { t } = useTranslation('Landing');

  const selectDealer = (dealer: Dealer) => {
    if (selectedDealer.code !== dealer.code) {
      trigger(EventTypes.Click, [
        getDealerBlock(dealer),
        getLinkBlock({
          href: '',
          inputField: '',
          text: dealer.name,
        }),
      ]);
      setSelectedDealer(dealer);
    }
  };

  return (
    <div className={styles.container} onClick={() => selectDealer(dealer)}>
      <div
        aria-checked={isSelected}
        role="radio"
        className={cc([
          styles.radioWrapper,
          { [styles.isSelected]: isSelected },
        ])}
      ></div>
      <div className={styles.dealerDetail}>
        <div className={styles.dealerBasicInfo}>
          <h2 className={styles.name}>{dealer.name}</h2>
          <span className={styles.distance}>
            ({dealer.distance?.toFixed(2)} {t('miles')})
          </span>
        </div>
        <span className={styles.address}>
          {dealer.address}, {dealer.state} {dealer.zip}
        </span>
      </div>
    </div>
  );
};

export default observer(SingleDealer);
