import { useContext, useMemo } from 'react';
import { DataLayerContext } from './DataLayerContext';
import { EventTrigger } from './models';
import { combineBlocks } from './utils';
import triggerEvent from './triggerEvent';

export default function useTrigger(): EventTrigger {
  const parentContext = useContext(DataLayerContext);

  return useMemo(
    () => (event, blocks) => {
      const blocksAsArray = Array.isArray(blocks) ? blocks : [blocks];

      const context = combineBlocks([parentContext(), ...blocksAsArray]);

      triggerEvent(event, context);
    },
    [parentContext]
  );
}
