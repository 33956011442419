import { action, makeObservable, observable } from 'mobx';

import { CATEGORY_KEYS, VEHICLE_BY_CATEGORIES } from '../../constants';
import {
  VehicleServiceResponse,
  Serie,
  VehicleStoreObject,
  VehicleFromService,
  Vehicle,
} from '../../models/Vehicle';
import { fetchVehicles } from '../../services/VehicleService';
import { sendHeight } from '../../services/PostMessageService';
import { getQueryParams } from '../../utils/getQueryParams';
import { transformSerieName } from '../../utils/transformSerieName';
import { transformVehicle } from '../../utils/transformVehicle';

import { globalStore } from '../index';

class VehicleStore {
  vehicles: VehicleStoreObject = {
    carsminivans: { vehicles: [], years: [] },
    trucks: { vehicles: [], years: [] },
    crossoverssuvs: { vehicles: [], years: [] },
    hybrids: { vehicles: [], years: [] },
    scionbytoyota: { vehicles: [], years: [] },
  };

  selectedCategory = 'carsminivans';
  selectedYear = '';
  selectedVehicleModel: Serie = { model: '', year: '' };
  selectedVehicles: Vehicle[] = [];
  selectedVehicle?: Vehicle = undefined;
  isUrlValid = true;
  isApiInvoked = false;

  constructor() {
    makeObservable(this, {
      selectedCategory: observable,
      selectedVehicle: observable,
      selectedVehicles: observable,
      selectedYear: observable,
      selectedVehicleModel: observable,
      isUrlValid: observable,
      isApiInvoked: observable,
    });
  }

  @action.bound setCategory = (category: string) => {
    this.selectedCategory = category;
    // to preserve selected category in case that user refresh estimator page
    localStorage.setItem('selectedCategory', category);
  };

  setCategoryFromQueryParams = (model: string) => {
    // If user refresh estimator page get category from model param
    this.selectedCategory = VEHICLE_BY_CATEGORIES[model].split(',')[0];
  };

  @action.bound changeYear = (year: string) => {
    this.selectedYear = year;
  };

  sortArrayAsc = () => {
    CATEGORY_KEYS.forEach(category => {
      this.vehicles[category as keyof VehicleStoreObject].years.sort((a, b) =>
        a.value > b.value ? 1 : -1
      );
    });
  };

  setVehicleFromResponse = (
    vehicle: VehicleFromService,
    category: keyof VehicleStoreObject
  ) => {
    // Preventing duplicating vehicles on browser back button
    // Back button don't refresh page it just re-renders component and calls getVehicles API again
    const vehicleIndex = this.vehicles[category]?.vehicles.findIndex(
      singleVehicle => singleVehicle.vin === vehicle.vin
    );

    if (vehicleIndex !== -1) {
      return;
    }

    const singleVehicle = transformVehicle(vehicle, category);

    this.vehicles[category].vehicles.push(singleVehicle);

    // Adding only unique years per category.
    const yearIndex = this.vehicles[category].years.findIndex(
      year => year.value === singleVehicle.year
    );

    if (yearIndex === -1) {
      this.vehicles[category].years.push({
        value: singleVehicle.year,
        label: singleVehicle.year,
      });
    }
  };

  @action.bound getVehicles = async (dealer: string) => {
    this.isUrlValid = true;
    globalStore.isFetching = true;

    try {
      const response = await fetchVehicles(dealer);
      if (!response.data) {
        return;
      }

      const vehicleResults: VehicleServiceResponse = response.data;

      vehicleResults.vehicles.forEach(vehicle => {
        const categories = VEHICLE_BY_CATEGORIES[
          transformSerieName(vehicle.model)
        ] as keyof VehicleStoreObject;

        categories &&
          categories.split(',').forEach(category => {
            this.setVehicleFromResponse(
              vehicle,
              category as keyof VehicleStoreObject
            );
          });
      });

      this.sortArrayAsc();

      const { year, model } = getQueryParams();

      // If we have query params year and model that means user has reloaded estimator page
      // so we need to fetch selected vehicle, model and year
      if (year && model) {
        this.setCategoryFromQueryParams(model as string);
        this.setVehicleFromQueryParams(model as string, year as string);
      }

      this.selectedYear =
        this.vehicles[
          this.selectedCategory as keyof VehicleStoreObject
        ]?.years[0].value;
    } catch (e) {
      console.error(e);
    } finally {
      this.isApiInvoked = true;
      globalStore.isFetching = false;
      sendHeight();
    }
  };

  setVehicleFromQueryParams = (model: string, year: string) => {
    const category = this.selectedCategory;

    const vehicles = this.vehicles[
      category as keyof VehicleStoreObject
    ].vehicles.filter(
      singleVehicle =>
        singleVehicle.year === year &&
        transformSerieName(singleVehicle.model) === model
    );

    if (!vehicles[0]) {
      this.isUrlValid = false;
    } else {
      this.selectedVehicles = vehicles;
      this.selectedVehicleModel = {
        model: vehicles[0].model,
        year: vehicles[0].year,
      };
    }
  };

  setSelectedVehicle = (vehicle: Vehicle) => {
    this.selectedVehicle = vehicle;
  };

  setSelectedVehicles = () => {
    const tempVehicles: Vehicle[] = [];

    this.vehicles[
      this.selectedCategory as keyof VehicleStoreObject
    ].vehicles.forEach(vehicle => {
      if (
        vehicle.model === this.selectedVehicleModel.model &&
        vehicle.year === this.selectedVehicleModel.year
      ) {
        tempVehicles.push(vehicle);
      }
    });

    this.selectedVehicles = tempVehicles;
  };

  @action.bound handleSerieSelect = (serie: Serie) => {
    this.selectedVehicleModel = {
      model: serie.model,
      year: serie.year,
    };

    this.setSelectedVehicles();
  };
}

export default VehicleStore;
