const tagsToReplace = {
  '"': '&#34;',
  "'": '&#39;',
  '&': '&#38;',
  '<': '&lt;',
  '>': '&gt;',
};

export const sanitizeString = (input: string) => {
  return input.replace(
    /["'&<>]/g,
    tag => tagsToReplace[tag as keyof typeof tagsToReplace] || tag
  );
};
