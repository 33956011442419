import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AnalyticsContext from '../../../analytics/AnalyticsContext';
import { getListItemBlock, getSerieBlock } from '../../../analytics/blocks';
import { Serie, VehicleStoreObject } from '../../../models/Vehicle';

import { useStore } from '../../../stores';
import SingleVehicle from '../SingleVehicle';
import styles from './styles.module.scss';

const Vehicles: FC = () => {
  const {
    vehicleStore: { selectedCategory, vehicles, selectedYear },
  } = useStore();
  const { t } = useTranslation('VehicleSelect');

  const uniqueSeries = useMemo(() => {
    const vehiclesByYear: Serie[] = vehicles[
      selectedCategory as keyof VehicleStoreObject
    ].vehicles.filter(vehicle => vehicle.year === selectedYear);

    const uniqueValues: Serie[] = [];

    vehiclesByYear.forEach(vehicle => {
      const serie: Serie = { model: vehicle.model, year: vehicle.year };

      if (
        uniqueValues?.findIndex(
          serie => serie.model === vehicle.model && serie.year === vehicle.year
        ) === -1
      ) {
        uniqueValues.push(serie);
      }
    });

    return uniqueValues;
  }, [vehicles, selectedCategory, selectedYear]);

  return (
    <ul className={styles.vehiclesWrapper}>
      {uniqueSeries.map(
        (vehicle, index) =>
          vehicle.year === selectedYear && (
            <AnalyticsContext
              blocks={[
                getListItemBlock(index),
                getSerieBlock(vehicle, t(selectedCategory)),
              ]}
              key={`${vehicle.model}_${index}`}
            >
              <SingleVehicle serie={vehicle} />
            </AnalyticsContext>
          )
      )}
    </ul>
  );
};

export default observer(Vehicles);
