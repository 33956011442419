import cc from 'classcat';
import { FC, useMemo, useRef, useState } from 'react';
import qs from 'query-string';
import { observer } from 'mobx-react';
import CountUp from 'react-countup';

import Cta from '../../../components/Cta';
import { history, useStore } from '../../../stores';
import { getModelImageUrl } from '../../../utils/getModelImageUrl';
import CadModal from '../CadModal';
import ThankYouModal from '../ThankYouModal';
import { getNumberWithComa } from '../../../utils/getNumberWithComa';
import useTrigger from '../../../analytics/useTrigger';
import EventTypes from '../../../analytics/EventTypes';
import { getLinkBlock } from '../../../analytics/blocks';
import { getNumberWithoutComa } from '../../../utils/getNumberWithoutComa';
import PrintThis from '../PrintThis';
import { Offer } from '../../../models/Offer';
import styles from './styles.module.scss';

const FinancialDetails: FC = () => {
  const {
    vehicleStore: { selectedVehicleModel },
    estimatorStore: {
      financialParams,
      paymentResponse,
      selectedEstimatorType,
      visibleOffers,
    },
  } = useStore();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const queryParams = qs.parse(history.location.search);
  const trigger = useTrigger();

  const analyticsHandler = () => {
    trigger(
      EventTypes.Click,
      getLinkBlock({
        href: '',
        text: 'Contact Dealer',
        metrics: 'km-pe-complete',
      })
    );
  };

  const stringifiedVisibleOffers = JSON.stringify(visibleOffers);
  const totalDownPayment: number = useMemo(() => {
    const visibleOffers = JSON.parse(stringifiedVisibleOffers);
    let totalDownPayment = 0;

    totalDownPayment =
      getNumberWithoutComa(financialParams.downPayment) +
      getNumberWithoutComa(financialParams.estimatedTradeIn);

    visibleOffers.forEach((offer: Offer) => {
      if (offer.isSelected && offer.cashAmount) {
        totalDownPayment += offer.cashAmount;
      }
    });

    return totalDownPayment;
  }, [
    stringifiedVisibleOffers,
    financialParams.downPayment,
    financialParams.estimatedTradeIn,
  ]);

  const countUpRef = useRef(null);

  return (
    <>
      <div className={styles.container}>
        <div>
          <div className={styles.wrapper}>
            <div className={styles.imgWrapper}>
              {queryParams.model && (
                <img
                  src={getModelImageUrl(queryParams.model as string)}
                  alt={selectedVehicleModel.model}
                />
              )}
            </div>
            <div className={styles.detailsWrapper}>
              <div className={cc([styles.paymentDetail, styles.duration])}>
                <div className={styles.content}>
                  <h4>{financialParams.term.value}</h4>
                  <span>Months</span>
                </div>
              </div>
              <span className={styles.divider} />
              {selectedEstimatorType === 'finance' && (
                <>
                  <div className={cc([styles.paymentDetail, styles.estimated])}>
                    <div className={styles.content}>
                      <h4>{paymentResponse.rate}%</h4>
                      <span>Estimated APR</span>
                    </div>
                    <p>{paymentResponse.disclaimers?.aprRate}</p>
                  </div>
                </>
              )}
              {selectedEstimatorType === 'lease' && (
                <>
                  <div className={cc([styles.paymentDetail, styles.estimated])}>
                    <div className={styles.content}>
                      <h4>12,000</h4>
                      <span>Annual Mileage</span>
                    </div>
                  </div>
                </>
              )}
              <span className={styles.divider} />
              <div className={cc([styles.paymentDetail, styles.downPayment])}>
                <div className={styles.content}>
                  <h4>${getNumberWithComa(totalDownPayment) || 0}</h4>

                  <span>
                    {selectedEstimatorType === 'lease'
                      ? 'Amount Due at Signing'
                      : 'Down Payment'}
                  </span>
                </div>
                {selectedEstimatorType === 'finance' && (
                  <p>{paymentResponse.disclaimers?.downPayment}</p>
                )}
                {selectedEstimatorType === 'lease' && (
                  <p>
                    Includes First Monthly Payment, Cash Out of Pocket, and
                    Acquisition Fee. Excludes tax, title, licensing, fees,
                    applicable rebates or cash offers, and security deposit.
                  </p>
                )}
              </div>
              <span className={styles.divider} />
              <div className={cc([styles.paymentDetail, styles.perMonth])}>
                {selectedEstimatorType === 'finance' && (
                  <span className={styles.paymentDetailLabel}>Finance for</span>
                )}
                {selectedEstimatorType === 'lease' && (
                  <span className={styles.paymentDetailLabel}>Lease for</span>
                )}
                <div className={styles.serieWrapper}>
                  {selectedVehicleModel.year}{' '}
                  {selectedVehicleModel.model?.toUpperCase()}
                </div>
                <div className={styles.content} ref={countUpRef}>
                  <h4>
                    <CountUp
                      duration={0.75}
                      end={paymentResponse.monthlyPayment ?? 0}
                      preserveValue
                      prefix="$"
                      separator=","
                    >
                      {({ countUpRef }) => (
                        <div>
                          <span
                            className={styles.animatedNumber}
                            ref={countUpRef}
                          />
                        </div>
                      )}
                    </CountUp>
                    <sub>/month</sub>
                  </h4>
                </div>
                <p>{paymentResponse.disclaimers?.monthlyPayment}</p>
              </div>
            </div>
          </div>
          <div className={styles.ctaWrapper}>
            <Cta
              isPrimary
              title="Contact Dealer"
              onClick={() => {
                setIsModalOpen(true);
                analyticsHandler();
              }}
            />
          </div>
        </div>
      </div>
      <PrintThis />
      <div className={styles.disclaimerWrapper}>
        {selectedEstimatorType === 'finance' ? (
          <>
            <div className={styles.disclaimerTitle}>Finance Terms</div> Payments
            calculated using this tool are ESTIMATES ONLY and do not include
            applicable taxes, title, licensing and fees. ACTUAL PRICES AND
            PAYMENTS MAY BE DIFFERENT. Financing payment calculations are based
            on APR and term. Available on approved credit to very well qualified
            customers through Toyota Financial Services and participating Toyota
            dealers on a used vehicle. Not all customers will qualify.
          </>
        ) : (
          <>
            <div className={styles.disclaimerTitle}>Lease Terms</div> Payments
            calculated using this tool are ESTIMATES ONLY and do not include
            applicable taxes, title, licensing and fees. ACTUAL PRICES AND
            PAYMENTS MAY BE DIFFERENT. Lease payment calculations are based on
            term and annual mileage. Available on approved credit to very well
            qualified customers through Toyota Financial Services and
            participating Toyota dealers on a used vehicle. Not all customers
            will qualify.
          </>
        )}
      </div>
      <CadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        selectedVehicleSerie={selectedVehicleModel}
        openThankYouModal={() => setIsThankYouModalOpen(true)}
      />
      <ThankYouModal
        isOpen={isThankYouModalOpen}
        onClose={() => setIsThankYouModalOpen(false)}
      />
    </>
  );
};
export default observer(FinancialDetails);
