import { action, computed, makeObservable, observable } from 'mobx';
import { AnnualMileage, CreditScores, TermLength } from '../../constants';
import { FinancialParams } from '../../models/FinancialParams';
import {
  Offer,
  OfferServiceResponse,
  PaymentDetailsRequest,
  PaymentDetailsResponse,
  PaymentResponses,
  PaymentServiceOffer,
} from '../../models/Offer';
import { Vehicle } from '../../models/Vehicle';
import { getOffers } from '../../services/OfferService';
import { getPaymentDetails } from '../../services/PaymentDetailsService';
import { sendHeight } from '../../services/PostMessageService';
import { changePageWithParams } from '../../utils/changePageWithParams';
import { getNumberWithoutComa } from '../../utils/getNumberWithoutComa';
import { getQueryParams } from '../../utils/getQueryParams';
import { transformSerieName } from '../../utils/transformSerieName';
import { globalStore, vehicleStore } from '../index';

class EstimatorStore {
  selectedEstimatorType: 'finance' | 'lease' = 'finance';

  isKmPeStartTriggered = false;
  isBestPriceCalculated: boolean = false;
  isOfferListChanged: boolean = true;

  offers: Offer[] = [];
  visibleOffers: Offer[] = [];

  financialParams: FinancialParams = {
    creditScore: CreditScores[0],
    term: TermLength[TermLength.length - 1],
    mileage: AnnualMileage[0],
    downPayment: '2,000',
    estimatedTradeIn: '0',
  };

  paymentResponse: PaymentResponses = {
    downPayment: 0,
    purchaseType: 'buy',
    term: 0,
    tier: '1+',
    tradeIn: 0,
  };

  constructor() {
    makeObservable(this, {
      financialParams: observable,
      isBestPriceCalculated: observable,
      isKmPeStartTriggered: observable,
      isOfferListChanged: observable,
      offers: observable,
      paymentResponse: observable,
      selectedEstimatorType: observable,
      visibleOffers: observable,
    });
  }

  @action.bound resetStore = () => {
    this.financialParams = {
      creditScore: CreditScores[0],
      term: TermLength[TermLength.length - 1],
      mileage: AnnualMileage[0],
      downPayment: '2,000',
      estimatedTradeIn: '0',
    };
    this.isBestPriceCalculated = false;
    this.isOfferListChanged = true;
    this.offers = [];
    this.visibleOffers = [];

    this.paymentResponse = {
      downPayment: 0,
      purchaseType: 'buy',
      term: 0,
      tier: '1+',
      tradeIn: 0,
    };
  };

  @action.bound setFinancialParam = (field: string, value: any) => {
    this.financialParams[field as keyof FinancialParams] = value;
  };

  @action.bound setIsKmPeStartTriggered = () => {
    this.isKmPeStartTriggered = true;
  };

  @action.bound offerClick = (offerId: string) => {
    let offerIndex = this.visibleOffers.findIndex(
      offer => offer.offerId === offerId
    );

    if (offerIndex !== -1) {
      this.visibleOffers[offerIndex].isSelected =
        !this.visibleOffers[offerIndex].isSelected;
    }

    this.getCalculation();
  };

  @action.bound fetchOffers = async () => {
    globalStore.isFetching = true;

    try {
      const { model, year } = vehicleStore.selectedVehicleModel;

      const response = await getOffers(
        globalStore.zipcode,
        year,
        transformSerieName(model)
      );

      if (!response.data) {
        return;
      }

      const offerResults: OfferServiceResponse = response.data;

      offerResults.offerBundle.offers = offerResults.offerBundle.offers.filter(
        offer => offer.offerType === 'TCUV APR'
      );

      this.offers = offerResults.offerBundle.offers;

      this.setVisibleOffers();

      if (offerResults.offerBundle.offers.length === 0) {
        return;
      }
    } catch (e) {
      console.error(e);
      globalStore.setStatusBar(true, 'ErrorMessages:serviceNotResponding');
    } finally {
      globalStore.isFetching = false;
      this.getCalculation();
    }
  };

  transformOatmsLevel = (level?: string) => (level === '0' ? '1+' : level);

  @action.bound setVisibleOffers = async () => {
    this.visibleOffers = [];

    this.offers.forEach(offer => {
      if (
        offer.offerType === 'TCUV APR' &&
        this.financialParams.creditScore.value ===
          this.transformOatmsLevel(offer.apr?.tiers.tier[0].level) &&
        this.financialParams.term.value ===
          offer.apr?.tiers.tier[0].term[0].duration
      ) {
        this.visibleOffers.push(offer);
      }
    });

    this.isOfferListChanged = true;
    sendHeight();
  };

  getOfferData = (offer: Offer) => {
    const offerCategories: any = {
      'TCUV APR': 'apr',
      'TCUV Cash': 'cash',
      'TCUV Lease': 'lease',
    };

    return {
      offerCategory: offerCategories[offer.offerType] || 'cash',
      offerId: offer.offerId,
      offerType: offer.optionType,
      ...(offer.offerType !== ('TCUV APR' || 'TCUV Lease') && {
        amount: offer.cashAmount,
      }),
      ...(offer.offerType === 'TCUV APR' && {
        rate: offer.apr?.tiers.tier[0].term[0].rate || 0,
      }),
    };
  };

  @computed preparePaymentDetailsForRequest = (vehicle: Vehicle) => {
    //Object initialization
    const vehicleDetails: PaymentDetailsRequest = {
      vehicle: {
        series: transformSerieName(vehicle.model),
        year: vehicle.year,
        modelCode: vehicle.code,
        vin: vehicle.vin,
        msrp: vehicle.price,
        mileage: vehicle.miles ? getNumberWithoutComa(vehicle.miles) : 0,
      },
      offers: [],
      paymentRequests: [
        {
          purchaseType: 'buy',
          downPayment: getNumberWithoutComa(this.financialParams.downPayment),

          tradeIn: getNumberWithoutComa(this.financialParams.estimatedTradeIn),
          tier: this.financialParams.creditScore.value,
          term: this.financialParams.term.value,
        },
      ],
    };

    if (!this.isBestPriceCalculated) {
      vehicleDetails.paymentRequests.push({
        purchaseType: 'buy',
        downPayment: getNumberWithoutComa(this.financialParams.downPayment),
        tradeIn: getNumberWithoutComa(this.financialParams.estimatedTradeIn),
        tier: this.financialParams.creditScore.value,
        term: 60,
      });

      this.offers.forEach(offer => {
        if (
          ([60, 72].includes(offer.apr?.tiers.tier[0].term[0].duration ?? -1) &&
            this.financialParams.creditScore.value ===
              this.transformOatmsLevel(offer.apr?.tiers.tier[0].level)) ||
          offer.offerType !== ('TCUV APR' || 'TCUV Lease')
        ) {
          const preparedOffer = this.getOfferData(offer);
          vehicleDetails.offers.push(preparedOffer);
        }
      });
    } else if (this.isOfferListChanged) {
      this.visibleOffers.forEach(offer => {
        const preparedOffer = this.getOfferData(offer);
        vehicleDetails.offers.push(preparedOffer);
      });
    } else {
      this.offers.forEach(offer => {
        if (offer.isSelected) {
          const preparedOffer = this.getOfferData(offer);
          vehicleDetails.offers.push(preparedOffer);
        }
      });
    }

    return vehicleDetails;
  };

  // Set selected offers from estimator service response.
  updateOffersSelection = (paymentOffers: PaymentServiceOffer[]) => {
    this.offers.forEach(offer => {
      offer.isSelected = false;
      offer.isDisabled = false;

      paymentOffers.forEach(paymentOffer => {
        if (paymentOffer.offerId === offer.offerId) {
          offer.isSelected = true;
        }
      });
    });
  };

  // If APR or Cash offer is selected disable all other apr/cash offers. (not applied on misc offers)
  updateOffersAvailability = () => {
    const selectedOffers = this.offers.filter(offer => offer.isSelected);

    const hasCashOrAprOffer =
      this.visibleOffers.findIndex(
        offer =>
          offer.isSelected &&
          (offer.offerType === 'TCUV Cash' || offer.offerType === 'TCUV APR')
      ) !== -1;

    if (hasCashOrAprOffer) {
      this.visibleOffers.forEach(visibleOffer => {
        if (
          !selectedOffers.includes(visibleOffer) &&
          visibleOffer.offerType !== 'TCUV Misc'
        ) {
          visibleOffer.isDisabled = true;
        }
      });
    }
  };

  @action.bound getCalculation = async () => {
    globalStore.isFetchingCalculation = true;

    const paymentDetailsRequest: PaymentDetailsRequest[] = [];

    const { vin } = getQueryParams();

    if (!this.isBestPriceCalculated && !vin) {
      vehicleStore.selectedVehicles.forEach(vehicle => {
        paymentDetailsRequest.push(
          this.preparePaymentDetailsForRequest(vehicle)
        );
      });
    } else {
      if (vehicleStore.selectedVehicle)
        paymentDetailsRequest.push(
          this.preparePaymentDetailsForRequest(vehicleStore.selectedVehicle)
        );
    }

    try {
      const response = await getPaymentDetails(
        globalStore.zipcode,
        paymentDetailsRequest
      );

      const paymentDetailsResponse: PaymentDetailsResponse = response.data;

      vehicleStore.selectedVehicle = vehicleStore.selectedVehicles.find(
        vehicle =>
          vehicle.vin === paymentDetailsResponse.lowestPayment.vehicle.vin
      );

      changePageWithParams(
        '/estimator',
        {
          ...getQueryParams(),
          vin: paymentDetailsResponse.lowestPayment.vehicle.vin,
        },
        true
      );

      this.paymentResponse = paymentDetailsResponse.lowestPayment.payment;
      const paymentOffers = paymentDetailsResponse.lowestPayment.payment.offers;

      if (
        !this.paymentResponse.monthlyPayment ||
        this.paymentResponse.monthlyPayment <= 0 ||
        !this.paymentResponse.rate ||
        this.paymentResponse.rate < 0
      ) {
        globalStore.setStatusBar(true, 'ErrorMessages:serviceNotResponding');
      }

      this.financialParams.term =
        TermLength.find(
          termLength => termLength.value === this.paymentResponse.term
        ) ?? TermLength[TermLength.length - 1];

      if (paymentOffers) {
        this.updateOffersSelection(paymentOffers);
        this.updateOffersAvailability();
      }

      this.isBestPriceCalculated = true;
      this.isOfferListChanged = false;
    } catch (e) {
      console.log(e);
      globalStore.setStatusBar(true, 'ErrorMessages:serviceNotResponding');
    } finally {
      globalStore.isFetchingCalculation = false;
    }
  };
}

export default EstimatorStore;
