import { useState } from 'react';

export const useDisclaimerModal = () => {
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] =
    useState<boolean>(false);

  const openDisclaimerModal = () => {
    setIsDisclaimerModalOpen(true);
  };

  const closeDisclaimerModal = () => {
    setIsDisclaimerModalOpen(false);
  };

  return {
    closeDisclaimerModal,
    isDisclaimerModalOpen,
    openDisclaimerModal,
  };
};
