import axios from 'axios';

import { OatServiceParams, PaymentDetailsRequest } from '../models/Offer';

const params: OatServiceParams = {
  brandId: 1,
  clientId: process.env.REACT_APP_OAT_CLIENT_ID,
};

export async function getPaymentDetails(
  zipcode: string,
  body: PaymentDetailsRequest[]
) {
  return axios.post(
    `${process.env.REACT_APP_OAT_API}/v1/pe/cuv/payments/${zipcode}`,
    body,
    { params, headers: { 'x-api-key': process.env.REACT_APP_OAT_API_KEY } }
  );
}
