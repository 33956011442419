import { createContext, FC, useContext } from 'react';
import { useLocalObservable } from 'mobx-react';
import { createHashHistory } from 'history';

import GlobalStore from './GlobalStore';
import DealerStore from './DealerStore';
import VehicleStore from './VehicleStore';
import EstimatorStore from './EstimatorStore';

export const history = createHashHistory();

export interface StoreContextInterface {
  dealerStore: DealerStore;
  globalStore: GlobalStore;
  vehicleStore: VehicleStore;
  estimatorStore: EstimatorStore;
}

export const StoreContext = createContext<StoreContextInterface | null>(null);

export const dealerStore = new DealerStore();
export const globalStore = new GlobalStore();
export const vehicleStore = new VehicleStore();
export const estimatorStore = new EstimatorStore();

export const StoreProvider: FC = ({ children }) => {
  const store = useLocalObservable(() => ({
    dealerStore,
    globalStore,
    vehicleStore,
    estimatorStore,
  }));

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => {
  const store = useContext(StoreContext);

  if (!store) {
    throw new Error('StoreProvider has not been injected');
  }

  return store;
};
