import React from 'react';

import styles from './styles.module.scss';

interface HeadlineProps {
  dividedText?: string;
  subTitle?: string;
  title: string;
}

const Headline: React.FC<HeadlineProps> = ({
  dividedText,
  subTitle,
  title,
}) => {
  return (
    <div className={styles.headline}>
      <h1 className={styles.title}>
        {title}
        {dividedText && (
          <>
            <div className={styles.divider}></div>
            <div>{dividedText}</div>
          </>
        )}
      </h1>
      {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
    </div>
  );
};

export default Headline;
