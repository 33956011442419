import { Dealer } from '../../models/Dealer';
import { FinancialParams } from '../../models/FinancialParams';
import { Offer } from '../../models/Offer';
import { Serie } from '../../models/Vehicle';
import { Vehicle } from '../../models/Vehicle';

import EventTypes from '../EventTypes';
import { AnalyticsBlockType } from '../models';
import { LinkBlock } from './models';

const getBreakpoint = () => {
  if (typeof window === 'undefined') {
    return 'unknown';
  }

  const { innerWidth } = window;
  if (innerWidth < 768) {
    return 'mobile';
  } else {
    return 'desktop';
  }
};

export const getDealerBlock = (dealer: Dealer) => {
  return {
    dealer_brand: dealer.brand || 'Toyota',
    dealer_city: dealer.city,
    dealer_code: dealer.code,
    dealer_name: dealer.name,
    dealer_state: dealer.state,
    dealer_zipcode: dealer.zip,
  };
};

export const getGlobalBlock = (zipcode: string = '', tdaCode: string) => ({
  tda_code: tdaCode,
  breakpoint: getBreakpoint(),
  zipcode,
});

export const getInputErrorBlock = (message: string) => ({
  displayed_error_message: message,
});

const sanitizeLinkText = (text: string) => {
  return text
    .replace(/(\r\n|\n|\r)/gm, '')
    .trim()
    .replace(/ /g, '_');
};

const buildUrlFromHref = (href: string) => {
  if (!href) {
    return href;
  } else if (href.charAt(0) === '#') {
    // href starts with '#' tack on window.location.href
    return window.location.href + href;
  } else if (href.charAt(0) === '/') {
    // href starts with '/' tack on window.location.origin
    return window.location.origin + href;
  }

  return href;
};

export const getLinkBlock = ({
  href = '',
  inputField = '',
  metrics = '',
  module = '',
  text = 'Link',
  typeTitle = '',
}: LinkBlock) => {
  const km = Array.isArray(metrics) ? metrics.join(',') : metrics;

  const block: AnalyticsBlockType = {
    link_action: km ? EventTypes.KmClick : EventTypes.Click,
    link_href: href,
    link_input_field: inputField,
    link_module: module,
    link_text: sanitizeLinkText(text),
    link_type_title: typeTitle,
    link_url: buildUrlFromHref(href),
  };

  if (km) {
    block.metrics = km;
  }

  return block;
};

export const getListItemBlock = (index = 0) => ({
  list_item_index: `${index + 1}`,
});

export const getSectionBlock = (section: string) => ({
  content_section: section,
});

export const getSerieBlock = (serie: Serie, category: string) => ({
  series_brand: 'Toyota',
  series_category: category,
  series_code: serie.model,
  series_year: serie.year,
});

export const getVehicleBlock = (vehicle: Vehicle) => {
  return {
    series_accessories_name: vehicle.accessories?.join(','),
    series_brand: 'Toyota',
    series_code: vehicle.model,
    series_color_ext: vehicle.colorExt,
    series_color_ext_code: vehicle.colorExtCode,
    series_color_int: vehicle.colorInt,
    series_color_int_code: vehicle.colorIntCode,
    series_engine: vehicle.engine,
    series_fuel_type: vehicle.fuelType,
    series_grade: vehicle.grade,
    series_msrp: vehicle.msrp,
    series_transmision: vehicle.transmission,
    series_vin: vehicle.vin,
    series_year: vehicle.year,
  };
};

const getStringWithComma = (str?: string) => {
  if (!str?.length) {
    return '';
  }

  return str.length > 0 ? `${str},` : '';
};

export const getSelectedOffersBlock = (offers: Offer[]) => {
  const combinedOffer = {
    offer_type: '',
    offer_id: '',
    offer_expired_date: '',
    offer_term: '',
    offer_rate: '',
    offer_title: '',
    offer_cashback: '',
    offer_series_brand: 'toyota',
  };

  if (offers.length === 0) {
    return {};
  }

  offers.forEach(offer => {
    combinedOffer.offer_type += getStringWithComma(offer.offerType);
    combinedOffer.offer_id += getStringWithComma(offer.offerId);
    combinedOffer.offer_expired_date += getStringWithComma(offer.endDate);
    combinedOffer.offer_term += getStringWithComma(
      offer.apr?.tiers.tier[0].term[0].duration.toString()
    );
    combinedOffer.offer_rate += getStringWithComma(
      offer.apr?.tiers.tier[0].term[0].rate.toString()
    );
    combinedOffer.offer_title += getStringWithComma(offer.title);
    combinedOffer.offer_cashback += getStringWithComma(
      offer.cashAmount?.toString()
    );
  });

  return combinedOffer;
};

export const getOfferBlock = (offer: Offer) => {
  return {
    offer_type: offer.offerType,
    offer_id: offer.offerId,
    offer_expired_date: offer.endDate,
    offer_term: offer.apr?.tiers.tier[0].term[0].duration.toString(),
    offer_rate: offer.apr?.tiers.tier[0].term[0].rate.toString(),
    offer_title: offer.title,
    offer_cashback: offer.cashAmount?.toString(),
    offer_series_brand: 'toyota',
  };
};

export const getEstimatorBlock = (estimation: FinancialParams) => {
  return {
    pe_buy_lease: 'Buy',
    pe_credit_score: estimation.creditScore.value,
    pe_apr_term: estimation.term.value,
    pe_down_payment: estimation.downPayment.toString(),
    pe_trade_in_value: estimation.estimatedTradeIn.toString(),
  };
};
