import { useEffect, useState } from 'react';
import { Route, Router } from 'react-router-dom';

import ProtectedRoute from './pages/ProtectedRoute';
import Loader from './components/Loader';
import Landing from './pages/Landing';
import VehicleSelect from './pages/VehicleSelect';
import Estimator from './pages/Estimator';
import { StoreProvider, history, useStore } from './stores';
import './i18n';
import { initialize } from './services/PostMessageService';
import StatusBar from './components/StatusBar';
import './styles/app.scss';

initialize();

const AppWrapper: React.FC = ({ children }) => {
  const {
    globalStore: { setGlobalParams },
  } = useStore();

  useEffect(() => {
    setGlobalParams();
  });
  return <>{children}</>;
};

const App: React.FC = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const analyticsScript = document.createElement('script');
      analyticsScript.async = true;
      analyticsScript.src = process.env.REACT_APP_ANALYTICS_SCRIPT || '';
      document.body.appendChild(analyticsScript);
      setIsScriptsLoaded(true);
    }
  }, []);

  const [isScriptsLoaded, setIsScriptsLoaded] = useState(false);

  return (
    <StoreProvider>
      {isScriptsLoaded && (
        <AppWrapper>
          <Router history={history}>
            <Route path="/" exact component={Landing} />
            <ProtectedRoute path="/vehicleSelect">
              <VehicleSelect />
            </ProtectedRoute>
            <ProtectedRoute path="/estimator">
              <Estimator />
            </ProtectedRoute>
          </Router>
          <Loader />
          <StatusBar />
        </AppWrapper>
      )}
    </StoreProvider>
  );
};

export default App;
