import React, {
  createElement,
  ReactDOM,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';

import AnalyticsContext from './AnalyticsContext';
import { DataLayerContext } from './DataLayerContext';
import EventTypes from './EventTypes';
import { AnalyticsBlockType } from './models';
import useTrigger from './useTrigger';
import { combineBlocks } from './utils';

interface AnalyticsComponentProps {
  as?: keyof ReactDOM;
  blocks?: AnalyticsBlockType | AnalyticsBlockType[];
  event?: EventTypes;
  timeout?: number;
}

const AnalyticsComponent: React.FC<AnalyticsComponentProps> = ({
  as = 'div',
  blocks = [],
  children,
  event = EventTypes.PageLoad,
  timeout = 2000,
}) => {
  const trigger = useTrigger();
  const [ref, inView] = useInView();

  const contextGenerator = useContext(DataLayerContext);
  const context = useMemo(() => contextGenerator(), [contextGenerator]);

  const [hasFired, setHasFired] = useState(false);
  const [timeoutId, setTimeoutId] = useState<number>();

  const blocksAsArray = useMemo(
    () => (Array.isArray(blocks) ? blocks : [blocks]),
    [blocks]
  );

  const handleEventTrigger = useMemo(
    () => () => {
      trigger(event, combineBlocks([...blocksAsArray], context));
      setHasFired(true);
    },
    [blocksAsArray, context, event, trigger]
  );

  useEffect(() => {
    if (hasFired) {
      return;
    }

    if (!inView && timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
    } else if (inView && !timeoutId) {
      setTimeoutId(window.setTimeout(handleEventTrigger, timeout));
    }
  }, [hasFired, inView, handleEventTrigger, timeout, timeoutId]);

  return (
    <AnalyticsContext blocks={[...blocksAsArray]}>
      {createElement(as, { ref }, children)}
    </AnalyticsContext>
  );
};

export default AnalyticsComponent;
