import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getDealerBlock,
  getEstimatorBlock,
  getGlobalBlock,
  getLinkBlock,
  getSectionBlock,
  getSelectedOffersBlock,
  getVehicleBlock,
} from '../../../analytics/blocks';
import EventTypes from '../../../analytics/EventTypes';
import useTrigger from '../../../analytics/useTrigger';
import Cta from '../../../components/Cta';
import Modal, { ModalProps } from '../../../components/Modal';
import { useStore } from '../../../stores';
import styles from './styles.module.scss';

const ThankYouModal: FC<ModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation('ThankYouModal');
  const trigger = useTrigger();

  const {
    globalStore: { zipcode, tdaCode },
    dealerStore: { selectedDealer },
    estimatorStore: { visibleOffers, financialParams },
    vehicleStore: { selectedVehicle },
  } = useStore();

  const selectedOffers = visibleOffers.filter(offer => offer.isSelected);

  const analyticsHandler = (event: string, linkText?: string) => {
    trigger(event, [
      getGlobalBlock(zipcode, tdaCode),
      getSectionBlock('pe contact dealer form'),
      getEstimatorBlock(financialParams),
      getDealerBlock(selectedDealer),
      selectedVehicle ? getVehicleBlock(selectedVehicle) : {},
      getSelectedOffersBlock(selectedOffers),
      linkText ? getLinkBlock({ href: '', text: linkText }) : {},
    ]);
  };

  return (
    <Modal
      onClose={() => {
        onClose && onClose();
      }}
      isOpen={isOpen}
    >
      <div className={styles.modalContainer}>
        <h1>{t('title')}</h1>
        <p>{t('paragraph1', { dealerName: selectedDealer.name })}</p>
        <p>{t('paragraph2')}</p>
        <p>{t('paragraph3')}</p>
        <Cta
          isPrimary
          title="Continue"
          className={styles.cta}
          onClick={() => {
            onClose && onClose();
            analyticsHandler(EventTypes.Click, 'Continue');
          }}
        />
      </div>
    </Modal>
  );
};

export default ThankYouModal;
