import { FC } from 'react';

import Modal, { ModalProps } from '../../../components/Modal';
import { disclaimers } from '../../../constants';
import { useStore } from '../../../stores';
import styles from './styles.module.scss';

interface DisclaimerModalProps extends ModalProps {}

const DisclaimerModal: FC<DisclaimerModalProps> = ({ onClose, isOpen }) => {
  const {
    estimatorStore: { selectedEstimatorType },
  } = useStore();

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <div className={styles.modalContainer}>
        <div className={styles.title}>Disclaimers</div>
        <div className={styles.body}>
          <div className={styles.bodyContent}>
            <ul>
              {disclaimers[selectedEstimatorType]?.map(disclaimer => (
                <li className={styles.listElement} key={disclaimer.id}>
                  <aside>{disclaimer.id}</aside>
                  <p>{disclaimer.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DisclaimerModal;
