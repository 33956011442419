import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { getQueryParams } from '../../utils/getQueryParams';
import { changePageWithParams } from '../../utils/changePageWithParams';
import { isZipCode } from '../../utils/isZipCode';
import { useStore } from '../../stores';

const ProtectedRoute: FC<RouteProps> = ({ children, ...rest }) => {
  const { dealerCode, zipcode, model, year } = getQueryParams();
  const {
    vehicleStore: { handleSerieSelect },
  } = useStore();

  useEffect(() => {
    if (!zipcode || !isZipCode(zipcode as string)) {
      changePageWithParams('/', {}, true);
    } else if (!dealerCode) {
      changePageWithParams('/', { zipcode }, true);
    } else if (!model || !year) {
      changePageWithParams('/vehicleSelect', { zipcode, dealerCode }, true);
    }
  }, [dealerCode, handleSerieSelect, model, year, zipcode]);

  return (
    <Route
      {...rest}
      render={() => {
        return children;
      }}
    />
  );
};

export default observer(ProtectedRoute);
