import { history } from '../stores/index';
import qs from 'query-string';

import { getQueryParams } from './getQueryParams';

export const changePageWithParams = (
  pathname: string,
  params: any,
  removeUrlParams = false
) => {
  const { lang } = getQueryParams();

  if (removeUrlParams) {
    history.push({
      pathname,
      search: qs.stringify({ ...params, lang }),
    });
  } else {
    history.push({
      pathname,
      search: qs.stringify({ ...getQueryParams(), ...params }),
    });
  }
};
