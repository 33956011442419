enum EventTypes {
  PageLoad = 'aa-pageload',
  Click = 'aa-link',
  KmClick = 'km-link',
  KmPeShown = 'km-pe-shown',
  KmCadShown = 'km-cad-shown',
  KmCadStart = 'km-cad-start',
  KmCadComplete = 'km-cad-complete',
  KmFormSubmitServerSideError = 'km-form_submit-server_side_error',
}

export default EventTypes;
