import { action, makeObservable, observable } from 'mobx';
import i18n from 'i18next';

import { getQueryParams } from '../../utils/getQueryParams';
import { dealerStore } from '../index';

class GlobalStore {
  isFetching = false;
  isFetchingCalculation = false;
  isZipCodeValid = true;
  statusBar = {
    message: '',
    isVisible: false,
  };
  zipcode = '';
  tdaCode = '';
  onlySelectedVin = false;

  constructor() {
    makeObservable(this, {
      isFetching: observable,
      isFetchingCalculation: observable,
      isZipCodeValid: observable,
      zipcode: observable,
      statusBar: observable,
      tdaCode: observable,
      onlySelectedVin: observable,
    });
  }

  @action.bound public setGlobalParams = () => {
    const { zipcode } = getQueryParams();

    if (zipcode) {
      this.zipcode = zipcode as string;
      dealerStore.getDealers();
    }

    this.setLanguageFromQueryParams();
  };

  @action.bound public setLanguageFromQueryParams = () => {
    // grabs language from query string and sets it internally if present...
    const { lang } = getQueryParams();

    if (lang && (lang === 'es' || lang === 'en')) {
      i18n.changeLanguage(lang);
    }
  };

  @action.bound public setZipCode = (zipcode: string) => {
    this.zipcode = zipcode;
  };

  @action.bound public setIsZipCodeValid = (isZipCodeValid: boolean) => {
    this.isZipCodeValid = isZipCodeValid;
  };

  @action.bound public setStatusBar = (
    isStatusBarVisible: boolean,
    message: string
  ) => {
    this.statusBar.isVisible = isStatusBarVisible;
    this.statusBar.message = message;
  };

  @action.bound public setTdaCode = (tdaCode: string) => {
    this.tdaCode = tdaCode;
  };

  @action.bound public setOnlySelectedVin = (onlySelectedVin: boolean) => {
    this.onlySelectedVin = onlySelectedVin;
  };
}

export default GlobalStore;
