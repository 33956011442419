import { FC } from 'react';
import { observer } from 'mobx-react';

import Cta from '../../../components/Cta';
import useTrigger from '../../../analytics/useTrigger';
import EventTypes from '../../../analytics/EventTypes';
import {
  getDealerBlock,
  getEstimatorBlock,
  getGlobalBlock,
  getLinkBlock,
  getSectionBlock,
  getSelectedOffersBlock,
  getVehicleBlock,
} from '../../../analytics/blocks';
import styles from './styles.module.scss';
import { useStore } from '../../../stores';

const PrintThis: FC = () => {
  const {
    globalStore: { zipcode, tdaCode },
    dealerStore: { selectedDealer },
    estimatorStore: { financialParams, visibleOffers },
    vehicleStore: { selectedVehicle },
  } = useStore();
  const trigger = useTrigger();

  const selectedOffers = visibleOffers.filter(offer => offer.isSelected);

  const print = () => {
    trigger(EventTypes.Click, [
      getGlobalBlock(zipcode, tdaCode),
      getSectionBlock('payment estimator form'),
      getDealerBlock(selectedDealer),
      getEstimatorBlock(financialParams),
      selectedVehicle ? getVehicleBlock(selectedVehicle) : {},
      getSelectedOffersBlock(selectedOffers),
      getLinkBlock({ text: 'Print This', href: '' }),
    ]);
    window.print();
  };

  return (
    <div className={styles.printWrapper}>
      <Cta isSecondary onClick={print}>
        <span className={styles.printIcon} />
        <span>Print This</span>
      </Cta>
    </div>
  );
};

export default observer(PrintThis);
