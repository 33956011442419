import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cc from 'classcat';

import SelectInput from '../../../components/SelectInput';
import { CATEGORY_KEYS } from '../../../constants';
import { useStore } from '../../../stores';
import useTrigger from '../../../analytics/useTrigger';
import EventTypes from '../../../analytics/EventTypes';
import { getLinkBlock, getListItemBlock } from '../../../analytics/blocks';
import { VehicleStoreObject } from '../../../models/Vehicle';
import { Option } from '../../../models/Option';
import styles from './styles.module.scss';

const Categories: FC = () => {
  const {
    vehicleStore: { selectedCategory, setCategory, changeYear, vehicles },
  } = useStore();

  const trigger = useTrigger();
  const { t } = useTranslation('VehicleSelect');

  const onCategorySelect = (categoryOption: Option) => {
    onCategoryClick(categoryOption.value);
  };

  const onCategoryClick = (category: string, index?: number) => {
    if (category !== selectedCategory) {
      trigger(EventTypes.Click, [
        getLinkBlock({ href: '', text: t(category) }),
        getListItemBlock(index),
      ]);

      const categoryYears =
        vehicles[category as keyof VehicleStoreObject]?.years;

      changeYear(categoryYears.length > 0 ? categoryYears[0].value : '');

      setCategory(category);
    }
  };

  useEffect(() => {
    setCategory('carsminivans');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoryOptions = CATEGORY_KEYS.map(categoryKey => ({
    value: categoryKey,
    label: t(categoryKey),
  }));

  return (
    <>
      <ul className={styles.navWrapper}>
        {CATEGORY_KEYS.map((category, index) => (
          <li
            className={cc([
              styles.category,
              { [styles.active]: category === selectedCategory },
            ])}
            onClick={() => onCategoryClick(category, index)}
            key={category}
          >
            {t(category)}
          </li>
        ))}
      </ul>
      <div className={styles.selectInputCategory}>
        <SelectInput
          options={categoryOptions}
          onClick={onCategorySelect}
          value={{ value: selectedCategory, label: t(selectedCategory) }}
        />
      </div>
    </>
  );
};

export default observer(Categories);
