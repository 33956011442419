import { action, makeObservable, observable } from 'mobx';
import qs from 'query-string';

import { Dealer, DealersFromService, Organization } from '../../models/Dealer';
import { getDealers } from '../../services/DealerService';
import { sendHeight } from '../../services/PostMessageService';

import { globalStore, history } from '../index';

class DealerStore {
  dealers: Dealer[] = [];
  hasDealers = true;
  maxDealers = 10;
  selectedDealer: Dealer = { address: '', code: '', name: '', zip: '' };
  visibleDealers: Dealer[] = [];

  constructor() {
    makeObservable(this, {
      dealers: observable,
      hasDealers: observable,
      selectedDealer: observable,
      visibleDealers: observable,
    });
  }

  @action.bound public setSelectedDealer = (dealer: Dealer) => {
    this.selectedDealer = dealer;
  };

  findMainDealer = (contacts: any) => {
    const mainContact = {
      address: '',
      city: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      region: '',
      state: '',
      street: '',
      website: '',
      zip: '',
    };

    const foundMainDealer = contacts.filter(
      (_item: any) => _item.departmentName.value === 'Main Dealer'
    );

    if (foundMainDealer.length) {
      mainContact.street = foundMainDealer[0].postalAddress.lineOne.value;
      mainContact.city = foundMainDealer[0].postalAddress.cityName.value;
      mainContact.zip = foundMainDealer[0].postalAddress.postcode.value;
      mainContact.state =
        foundMainDealer[0].postalAddress.stateOrProvinceCountrySubDivisionID.value;
      mainContact.address = `${mainContact.street} ${mainContact.city}, ${mainContact.state} ${mainContact.zip}`;

      // find phone
      const foundPhone = foundMainDealer[0].telephoneCommunication.filter(
        (_item: any) => _item.channelCode.value === 'Phone'
      );
      if (foundPhone.length) {
        mainContact.phone = foundPhone[0].completeNumber.value;
      }

      // find website and email
      foundMainDealer[0].uricommunication.forEach((_item: any) => {
        switch (_item.channelCode.value) {
          case 'Email': {
            mainContact.email = _item.uriid.value;
            break;
          }
          case 'Website': {
            mainContact.website = _item.uriid.value;
            break;
          }
          default: {
            break;
          }
        }
      });
    }

    return mainContact;
  };

  @action.bound getTDACodeFromDealerResponse = (
    response: DealersFromService
  ) => {
    return response.showDealerLocatorDataArea.dealerMetaData[0].dealerMetaDataDetail[0].organization.reduce(
      (tda: string, orgItem: Organization) => {
        if (orgItem.code.value === 'Association') {
          return orgItem.party[0].specifiedOrganization.companyCode.value;
        }
        return tda;
      },
      ''
    );
  };

  @action.bound public getDealers = async () => {
    globalStore.isFetching = true;
    this.hasDealers = true;

    try {
      const response = await getDealers(globalStore.zipcode);

      if (!response.data) {
        return;
      }

      const dealerResults: DealersFromService = response.data;

      if (dealerResults.showDealerLocatorDataArea?.dealerLocator.length === 0) {
        this.hasDealers = false;
      }

      globalStore.setTdaCode(this.getTDACodeFromDealerResponse(dealerResults));

      this.dealers =
        dealerResults.showDealerLocatorDataArea?.dealerLocator[0].dealerLocatorDetail.map(
          dealer => {
            return {
              address:
                dealer.dealerParty.specifiedOrganization.postalAddress.lineOne
                  .value,
              code: dealer.dealerParty.partyID.value,
              distance: dealer.proximityMeasureGroup.proximityMeasure.value,
              name: dealer.dealerParty.specifiedOrganization.companyName.value,
              state:
                dealer.dealerParty.specifiedOrganization.postalAddress
                  .stateOrProvinceCountrySubDivisionID.value,
              zip: dealer.dealerParty.specifiedOrganization.postalAddress
                .postcode.value,
              city: dealer.dealerParty.specifiedOrganization.postalAddress
                .cityName.value,
              contact: {
                ...this.findMainDealer(
                  dealer.dealerParty.specifiedOrganization.primaryContact
                ),
                region:
                  dealer.dealerParty.specifiedOrganization.divisionCode.value,
              },
            };
          }
        );

      const { dealerCode } = qs.parse(history.location.search);

      this.selectedDealer =
        this.dealers.find(dealer => dealer.code === dealerCode) ||
        this.dealers[0];

      this.visibleDealers = this.dealers.slice(0, 3);
    } catch (e: any) {
      this.dealers = [];
      this.visibleDealers = [];

      if (e?.response?.status === 400) {
        globalStore.setIsZipCodeValid(false);
      }
      this.hasDealers = false;

      console.error('Invalid zipcode');
    }

    sendHeight();

    globalStore.isFetching = false;
  };

  @action.bound public showMoreDealers = () => {
    this.visibleDealers = this.dealers.slice(0, this.visibleDealers.length + 3); // We are showing 3 by 3 dealers
    setTimeout(() => {
      sendHeight();
    });
  };
}

export default DealerStore;
