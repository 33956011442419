import { Option } from '../models/Option';

export const CATEGORY_KEYS = [
  'carsminivans',
  'trucks',
  'crossoverssuvs',
  'hybrids',
  'scionbytoyota',
];

type VehicleObject = {
  [id: string]: string;
};

export const VEHICLE_BY_CATEGORIES: VehicleObject = {
  '86': 'carsminivans',
  avalon: 'carsminivans',
  camry: 'carsminivans',
  corolla: 'carsminivans',
  corollahatchback: 'carsminivans',
  corollaim: 'carsminivans',
  grcorolla: 'carsminivans',
  sienna: 'carsminivans',
  supra: 'carsminivans',
  yaris: 'carsminivans',
  yarishatchback: 'carsminivans',
  yarisia: 'carsminivans',
  yarisliftback: 'carsminivans',
  tacoma: 'trucks',
  tundra: 'trucks',
  '4runner': 'crossoverssuvs',
  'FJ Cruiser': 'crossoverssuvs',
  'c-hr': 'crossoverssuvs',
  corollacross: 'crossoverssuvs',
  highlander: 'crossoverssuvs',
  landcruiser: 'crossoverssuvs',
  rav4: 'crossoverssuvs',
  rav4prime: 'crossoverssuvs',
  sequoia: 'crossoverssuvs',
  venza: 'crossoverssuvs',
  bz4x: 'hybrids,crossoverssuvs',
  avalonhybrid: 'hybrids,carsminivans',
  camryhybrid: 'hybrids,carsminivans',
  corollahybrid: 'hybrids,carsminivans',
  toyotacrown: 'hybrids,carsminivans',
  highlanderhybrid: 'hybrids,crossoverssuvs',
  mirai: 'hybrids,carsminivans',
  prius: 'hybrids,carsminivans',
  priusc: 'hybrids,carsminivans',
  priusprime: 'hybrids,carsminivans',
  priusv: 'hybrids,carsminivans',
  pruisplugin: 'hybrids,carsminivans',
  rav4hybrid: 'hybrids,crossoverssuvs',
  frs: 'scionbytoyota',
  ia: 'scionbytoyota',
  im: 'scionbytoyota',
  iq: 'scionbytoyota',
  tc: 'scionbytoyota',
  xb: 'scionbytoyota',
  xd: 'scionbytoyota',
};

export type Disclaimer = { id: number; description: string };

export type Disclaimers = { finance: Disclaimer[]; lease?: Disclaimer[] };

export const CreditScores: Option[] = [
  { value: '1+', label: 'Excellent', extraLabel: '720+' },
  { value: '1', label: 'Great', extraLabel: '719-690' },
  { value: '2', label: 'Very Good', extraLabel: '689-670' },
  { value: '3', label: 'Good', extraLabel: '669-650' },
  { value: '4', label: 'Fair', extraLabel: '649-630' },
  { value: '5', label: 'Poor', extraLabel: '629-610' },
  { value: '6', label: 'Very Poor', extraLabel: '609-580' },
  { value: '7', label: 'Extremely Poor', extraLabel: '579-520' },
];

export const TermLength: Option[] = [
  { value: 24, label: '24 Months' },
  { value: 36, label: '36 Months' },
  { value: 48, label: '48 Months' },
  { value: 60, label: '60 Months' },
  { value: 72, label: '72 Months' },
];

export const AnnualMileage: Option[] = [
  { value: 10000, label: '10,000' },
  { value: 12000, label: '12,000' },
  { value: 15000, label: '15,000' },
];

export const disclaimers: Disclaimers = {
  finance: [
    {
      id: 1,
      description:
        'Please select an estimated credit score range to display Finance rates, offers and payment plans',
    },
    {
      id: 2,
      description:
        'This is the amount of cash you bring to the transaction and it is applied to reduce the amount financed.',
    },
    {
      id: 3,
      description:
        'The duration of the finance contract, usually expressed in months. For example, 24 months or 36 months.',
    },
    {
      id: 4,
      description:
        'Annual Percentage Rate (APR). The cost of your credit expressed as a yearly rate.',
    },
    {
      id: 5,
      description:
        'Your actual negotiated vehicle value may differ. If the actual negotiated value of your trade-in is lower than your valuation, you may need to make a larger down payment to qualify for the finance terms you have selected. The valuation is an estimate and is not based on an inspection of your vehicle. Your dealer and Toyota Financial Services have not participated in and are not bound by this valuation. If the payoff on your existing vehicle exceeds the sum of the value of the trade in vehicle plus any cash or rebates you apply to the transaction, you may be required to finance that difference or make a larger down payment.',
    },
    {
      id: 6,
      description:
        'Total Suggested Retail Price includes manufacturer and distributor options and delivery, process and handling fees, which may be subject to change at any time. Excludes taxes, title, license and dealer options, fees and charges.  Dealer price will vary.',
    },
  ],
};
