import { observer } from 'mobx-react';
import { FC } from 'react';
import { getLinkBlock } from '../../../analytics/blocks';
import EventTypes from '../../../analytics/EventTypes';
import useTrigger from '../../../analytics/useTrigger';

import Cta from '../../../components/Cta';
import SelectInput from '../../../components/SelectInput';
import TextInput from '../../../components/TextInput';
import { AnnualMileage, CreditScores, TermLength } from '../../../constants';
import { sendHeight } from '../../../services/PostMessageService';
import { useStore } from '../../../stores';
import { getNumberWithComa } from '../../../utils/getNumberWithComa';
import { getNumberWithoutComa } from '../../../utils/getNumberWithoutComa';
import Offers from './Offers';
import styles from './styles.module.scss';

interface FinancialParamsProps {
  openDisclaimerModal: () => void;
}

let timeout: any;

const FinancialParams: FC<FinancialParamsProps> = props => {
  const { openDisclaimerModal } = props;

  const {
    estimatorStore: {
      financialParams,
      getCalculation,
      setFinancialParam,
      paymentResponse,
      selectedEstimatorType,
      setVisibleOffers,
      setIsKmPeStartTriggered,
      isKmPeStartTriggered,
      visibleOffers,
    },
    vehicleStore: { selectedVehicle },
    globalStore: { setStatusBar },
  } = useStore();
  const trigger = useTrigger();

  const onFinancialParamChange = (field: string, value: any) => {
    let invalidInput = false;

    //Show error message if entered down payment is grater than vehicle estimated price
    if (
      field === 'downPayment' &&
      selectedVehicle &&
      value > selectedVehicle?.price
    ) {
      invalidInput = true;
    }

    if (!invalidInput) {
      setFinancialParam(field, value);
    }

    if (field === 'creditScore' || field === 'term') {
      setVisibleOffers();
      getCalculation();
      setTimeout(() => {
        sendHeight();
      });
    } else {
      clearTimeout(timeout);

      if (invalidInput) {
        setStatusBar(true, 'Invalid input');
      } else {
        setStatusBar(false, '');
        timeout = setTimeout(async () => {
          await getCalculation();
        }, 1500);
      }
    }
  };

  const analyticsHandler = (linkText: string, inputField?: string | number) => {
    trigger(EventTypes.Click, [
      getLinkBlock({
        href: '',
        text: linkText,
        metrics: !isKmPeStartTriggered ? 'km-pe-start' : undefined,
        inputField: inputField?.toString(),
      }),
    ]);

    setIsKmPeStartTriggered();
  };

  const handleEstimatedTradeInClick = () => {
    const windowProps =
      'toolbar=no,scrollbars=auto,resizable=yes,top=100,left=100,width=500,height=700, rel=noopener';
    const url = 'https://www.toyota.com/kbb/';

    window.open(url, '', windowProps);
  };

  return (
    <div className={styles.containerFull}>
      <div className={styles.container}>
        <div className={styles.financialParamsWrapper}>
          <div className={styles.singleParamWrapper}>
            <div className={styles.title}>
              <label>
                Estimated Credit Score<sup onClick={openDisclaimerModal}>1</sup>
              </label>
            </div>
            <div className={styles.inputWrapper}>
              <SelectInput
                onClick={e => {
                  onFinancialParamChange('creditScore', e);
                  analyticsHandler('estimated credit score', e.label);
                }}
                options={CreditScores}
                value={financialParams.creditScore}
              />
            </div>
          </div>
          <div className={styles.singleParamWrapper}>
            <div className={styles.title}>
              {selectedEstimatorType === 'finance' ? (
                <label>
                  Cash Down<sup onClick={openDisclaimerModal}>2</sup>
                </label>
              ) : (
                <label>
                  Cash Out of Pocket<sup onClick={openDisclaimerModal}>8</sup>
                </label>
              )}
            </div>

            <TextInput
              className={styles.inputWrapper}
              isSecondary
              onChange={e => {
                onFinancialParamChange(
                  'downPayment',
                  Number(e.currentTarget.value.replace(/[^\d]/gm, ''))
                );
              }}
              onFocus={e => {
                analyticsHandler(
                  'estimated down payment',
                  financialParams.downPayment
                );
                onFinancialParamChange(
                  'downPayment',
                  getNumberWithoutComa(e.currentTarget.value)
                );
              }}
              onBlur={e =>
                onFinancialParamChange(
                  'downPayment',
                  getNumberWithComa(e.currentTarget.value)
                )
              }
              value={financialParams.downPayment}
              prefix="$"
            />
          </div>
          <div className={styles.singleParamWrapper}>
            <div className={styles.title}>
              <label>
                Term Length<sup onClick={openDisclaimerModal}>3</sup>
              </label>
            </div>
            <div className={styles.inputWrapper}>
              <SelectInput
                onClick={e => {
                  onFinancialParamChange('term', e);
                  analyticsHandler(
                    'estimated term',
                    financialParams.term.label
                  );
                }}
                options={TermLength}
                value={financialParams.term}
              />
            </div>
          </div>
          <div className={styles.singleParamWrapper}>
            {selectedEstimatorType === 'finance' && (
              <>
                <div className={styles.title}>
                  <label>
                    Estimated APR<sup onClick={openDisclaimerModal}>4</sup>
                  </label>
                </div>
                <TextInput
                  className={styles.inputWrapper}
                  isSecondary
                  onChange={() => {}}
                  onFocus={() =>
                    analyticsHandler(
                      'estimated APR',
                      paymentResponse.rate || ''
                    )
                  }
                  value={`${paymentResponse.rate?.toString()}%` ?? ''}
                />
              </>
            )}

            {selectedEstimatorType === 'lease' && (
              <>
                <div className={styles.title}>
                  <label>
                    Annual Mileage<sup onClick={openDisclaimerModal}>6</sup>
                  </label>
                </div>
                <div className={styles.inputWrapper}>
                  <SelectInput
                    onClick={e => onFinancialParamChange('mileage', e)}
                    options={AnnualMileage}
                    value={financialParams.mileage}
                  />
                </div>
              </>
            )}
          </div>
          <div className={styles.singleParamWrapper}>
            <div className={styles.title}>
              <label>
                Estimated Trade-In Value
                <sup onClick={openDisclaimerModal}>5</sup>
              </label>
            </div>
            <TextInput
              className={styles.inputWrapper}
              isSecondary
              onChange={e => {
                onFinancialParamChange(
                  'estimatedTradeIn',
                  Number(e.currentTarget.value.replace(/[^\d]/gm, ''))
                );
              }}
              onFocus={e => {
                analyticsHandler(
                  'estimated trade in',
                  financialParams.estimatedTradeIn
                );
                onFinancialParamChange(
                  'estimatedTradeIn',
                  getNumberWithoutComa(e.currentTarget.value)
                );
              }}
              onBlur={e =>
                onFinancialParamChange(
                  'estimatedTradeIn',
                  getNumberWithComa(e.currentTarget.value)
                )
              }
              value={financialParams.estimatedTradeIn}
              prefix="$"
            />
            <Cta
              className={styles.estimatedTradeInWrapper}
              isSecondary
              onClick={() => {
                handleEstimatedTradeInClick();
                analyticsHandler('Estimated Trade-in');
              }}
            >
              Estimated Trade-In
            </Cta>
          </div>
        </div>

        {visibleOffers.length > 0 && <div className={styles.divider} />}
        <Offers />
      </div>
    </div>
  );
};
export default observer(FinancialParams);
