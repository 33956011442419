import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Headline from '../../components/Headline';
import ZipCodeInput from './ZipCodeInput';
import NoDealers from './NoDealers';
import { useStore } from '../../stores';
import DealersResult from './DealersResult';
import { getGlobalBlock, getSectionBlock } from '../../analytics/blocks';
import AnalyticsComponent from '../../analytics/AnalyticsComponent';
import styles from './styles.module.scss';

const Landing: FC = () => {
  const { t } = useTranslation('Common');

  const {
    dealerStore: { hasDealers },
    globalStore: { isZipCodeValid, zipcode, tdaCode },
  } = useStore();

  return (
    <AnalyticsComponent
      blocks={
        (getGlobalBlock(zipcode, tdaCode), getSectionBlock('find dealer'))
      }
    >
      <section className={styles.container}>
        <Headline title={t('title')} />
        <ZipCodeInput />
        {hasDealers ? <DealersResult /> : isZipCodeValid && <NoDealers />}
      </section>
    </AnalyticsComponent>
  );
};

export default observer(Landing);
