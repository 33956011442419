import axios from 'axios';

import { VehicleServiceBody } from '../models/Vehicle';

const url = `${process.env.REACT_APP_VEHICLES_API_URL}/vehicles`;

const body: VehicleServiceBody = {
  'security-token': 'tcuvpaymentestimator',
  type: 'cpo',
  'in-transit': false,
  'include-offers': false,
  'num-recs': 9999,
};

export async function fetchVehicles(dealer: string) {
  return axios.post(url, { ...body, dealer });
}
