import { observer } from 'mobx-react';
import { ChangeEvent, FC, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { getInputErrorBlock, getLinkBlock } from '../../../analytics/blocks';
import EventTypes from '../../../analytics/EventTypes';
import useTrigger from '../../../analytics/useTrigger';

import Cta from '../../../components/Cta';
import TextInput from '../../../components/TextInput';
import { useStore } from '../../../stores';
import { changePageWithParams } from '../../../utils/changePageWithParams';
import { isZipCode } from '../../../utils/isZipCode';
import styles from './styles.module.scss';

const ZipCodeInput: FC = () => {
  const trigger = useTrigger();

  const {
    dealerStore: { getDealers },
    globalStore: { isZipCodeValid, setZipCode, setIsZipCodeValid, zipcode },
  } = useStore();

  const analyticsBlocks = () => {
    return getLinkBlock({
      href: '',
      inputField: zipcode,
      text: 'Confirm Zip',
    });
  };

  const handleZipSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsZipCodeValid(true);

    if (zipcode.length === 0 || zipcode.length !== 5 || !isZipCode(zipcode)) {
      setIsZipCodeValid(false);

      trigger(EventTypes.Click, [
        analyticsBlocks(),
        getInputErrorBlock('Please re-enter Zip Code'),
      ]);

      return;
    }

    trigger(EventTypes.Click, [analyticsBlocks()]);

    getDealers();
    changePageWithParams('', { zipcode });
  };

  const changeZipCodeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const sanitizedZip = e.currentTarget.value.replace(/[^\d]/gm, '');
    setZipCode(sanitizedZip);
  };

  const { t } = useTranslation(['Ctas', 'Landing']);

  return (
    <div className={styles.container}>
      <form onSubmit={e => handleZipSubmit(e)}>
        <div className={styles.wrapper}>
          <TextInput
            error={!isZipCodeValid}
            maxLength={5}
            onChange={changeZipCodeHandler}
            value={zipcode}
            className={styles.inputField}
          />
          <div className={styles.ctaWrapper}>
            <Cta isPrimary type="submit">
              {t('confirmZip')}
            </Cta>
          </div>
        </div>
        {!isZipCodeValid && (
          <p className={styles.hasError}>{t('Landing:zipErrorMessage')}</p>
        )}
      </form>
      <p className={styles.message}>{t('Landing:zipMessage')}</p>
    </div>
  );
};

export default observer(ZipCodeInput);
