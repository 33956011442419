import { isValidEmail } from './isValidEmail';

const NUMBER_REGEX = /^[0-9]+$/;

export default function validate(
  field: string,
  value: any,
  preferedContact: 'email' | 'phone'
) {
  let errors: any = {};
  let hasError = false;

  errors[field] = {
    error: '',
    touched: true,
  };

  switch (field) {
    case 'firstName':
      if (!value.length) {
        errors.firstName.error = 'First name is required';
        hasError = true;
      }
      break;
    case 'lastName':
      if (!value.length) {
        errors.lastName.error = 'Last name is required';
        hasError = true;
      }
      break;
    case 'email':
      if (preferedContact === 'email') {
        errors.phone = { error: '', touched: true };

        if (!value.length) {
          errors.email.error = 'Email is required';
          hasError = true;
        } else if (!isValidEmail(value)) {
          errors.email.error = 'Email is invalid';
          hasError = true;
        }
      }
      break;
    case 'phone':
      if (preferedContact === 'phone') {
        errors.email = { error: '', touched: true };

        if (!value.length) {
          errors.phone.error = 'Phone is required';
          hasError = true;
        } else if (value.length !== 10 || !NUMBER_REGEX.test(value)) {
          errors.phone.error = 'Phone is invalid';
          hasError = true;
        }
      }
      break;

    default:
      break;
  }

  if (!hasError) {
    errors[field].error = '';
  }

  return errors;
}
