import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const NoDealers: FC = () => {
  const { t } = useTranslation('Landing');

  return <div className={styles.wrapper}>{t('noDealers')}</div>;
};

export default NoDealers;
