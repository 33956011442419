import { observer } from 'mobx-react';
import { FC } from 'react';
import { getLinkBlock } from '../../../analytics/blocks';
import EventTypes from '../../../analytics/EventTypes';
import useTrigger from '../../../analytics/useTrigger';

import { Serie } from '../../../models/Vehicle';
import { sendHeight } from '../../../services/PostMessageService';
import { useStore } from '../../../stores';
import { changePageWithParams } from '../../../utils/changePageWithParams';
import { getModelImageUrl } from '../../../utils/getModelImageUrl';
import { transformSerieName } from '../../../utils/transformSerieName';
import styles from './styles.module.scss';

interface SingleVehicleProps {
  serie: Serie;
}

const SingleVehicle: FC<SingleVehicleProps> = ({ serie }) => {
  const trigger = useTrigger();

  const {
    vehicleStore: { handleSerieSelect },
    estimatorStore: { resetStore },
  } = useStore();

  const onVehicleSelect = (serie: Serie) => {
    resetStore();
    handleSerieSelect(serie);

    trigger(
      EventTypes.Click,
      getLinkBlock({ href: '/estimator', text: `${serie.model} ${serie.year}` })
    );

    changePageWithParams('/estimator', {
      model: transformSerieName(serie.model),
      year: serie.year,
    });
  };

  return (
    <li
      className={styles.vehicleWrapper}
      onClick={() => onVehicleSelect(serie)}
    >
      <div className={styles.imageWrapper}>
        <img
          src={getModelImageUrl(serie.model)}
          alt={serie.model}
          onLoad={() => {
            sendHeight();
          }}
        />
      </div>
      <div className={styles.description}>{serie.model}</div>
    </li>
  );
};

export default observer(SingleVehicle);
