import React, { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface LinkProps {
  children?: ReactNode;
  className?: string;
  href: string;
  isExternal?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const Link: React.FC<LinkProps> = props => {
  const { children, className, href, isExternal, onClick } = props;

  return (
    <RouterLink className={className} onClick={onClick} rel={isExternal ? 'noreferrer' : 'noopener'} target={isExternal ? '"_blank"' : '_self'} to={href}>
      {children}
    </RouterLink>
  );
};

export default Link;
