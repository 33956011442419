import { Vehicle, VehicleFromService } from '../models/Vehicle';
import { getNumberWithComa } from './getNumberWithComa';

export const transformVehicle = (
  vehicle: VehicleFromService,
  category: string
): Vehicle => {
  return {
    accessories: vehicle.accessories.map(accessorie => accessorie.description),
    category,
    code: vehicle.code,
    colorExt: vehicle['exterior-color'],
    colorExtCode: vehicle['exterior-color-code'],
    colorInt: vehicle['interior-color'],
    colorIntCode: vehicle['interior-color-code'],
    cylinder: vehicle.cylinders,
    description: vehicle.description,
    engine: vehicle.engine,
    engingDescription: vehicle['engine-description'],
    fuelType: vehicle['fuel-type'],
    grade: vehicle.grade,
    miles: getNumberWithComa(vehicle.miles.toString()) || '',
    model: vehicle.model,
    msrp: vehicle.msrp,
    price: vehicle.price,
    transmission: vehicle.transmission,
    vin: vehicle.vin,
    year: vehicle.year,
  };
};
