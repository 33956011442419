import React, {
  FocusEvent,
  KeyboardEvent,
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import cc from 'classcat';

import { Option } from '../../models/Option';
import styles from './styles.module.scss';

interface SelectInputProps {
  isSecondary?: boolean;
  onClick: (value: Option) => void;
  onFocus?: any;
  options: Option[];
  value: Option;
}

const SelectInput: React.FC<SelectInputProps> = props => {
  const { isSecondary = false, onClick, options, value, onFocus } = props;
  const [isOpen, setOpen] = useState(false);

  const [liElementRefs, setLiElementRefs] = useState<
    Array<React.RefObject<HTMLLIElement>>
  >([]);
  const selectInputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLiElementRefs(refs =>
      options.map((_, index) => refs[index] || createRef<HTMLLIElement>())
    );
  }, [options.length, options]);

  const handleSelectClick = () => {
    setOpen(!isOpen);
  };

  const handleOptionClick = (value: Option) => {
    setOpen(false);
    onClick(value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      setOpen(!isOpen);
    }
  };

  const handleKeyPressList = (
    event: KeyboardEvent<HTMLUListElement | HTMLLIElement>,
    option: Option
  ) => {
    event.preventDefault();

    const activeElement = document.activeElement;
    const activeElementIndex = liElementRefs.findIndex(
      ref => ref.current === activeElement
    );

    if (
      (event.code === 'ArrowUp' || (event.shiftKey && event.code === 'Tab')) &&
      activeElementIndex > 0
    ) {
      liElementRefs[activeElementIndex - 1].current?.focus(); // NOSONAR
    }

    if (
      (event.code === 'ArrowDown' ||
        (!event.shiftKey && event.code === 'Tab')) &&
      activeElementIndex < liElementRefs.length - 1
    ) {
      liElementRefs[activeElementIndex + 1].current?.focus(); // NOSONAR
    }

    if (event.code === 'Escape') {
      setOpen(false);
      selectInputRef.current?.focus(); // NOSONAR
    }

    if (event.key === ' ' || event.key === 'Enter') {
      onClick(option);
      setOpen(false);
      selectInputRef?.current?.focus(); // NOSONAR
    }
  };

  interface RelatedTarget {
    nodeName?: string;
    dataset?: {
      option: boolean;
    };
  }

  const handleFocusOut = (e: FocusEvent<HTMLElement>) => {
    const relatedTarget = e.relatedTarget as RelatedTarget;
    //Check if not clicked on list option and close list

    if (relatedTarget === null || !relatedTarget?.dataset?.option) {
      setOpen(false);
    }
  };

  return (
    <div
      className={cc([
        styles.wrapper,
        { [styles.isOpen]: isOpen, [styles.secondary]: isSecondary },
      ])}
      tabIndex={-1}
      onBlur={handleFocusOut}
      onFocus={onFocus}
    >
      <div
        aria-controls=""
        aria-expanded={isOpen}
        className={styles.select}
        onClick={handleSelectClick}
        onKeyPress={handleKeyPress}
        ref={selectInputRef}
        role="listbox"
        tabIndex={0}
      >
        <span>{value.label}</span>
        <span
          className={cc([
            { [styles.arrowUp]: isOpen, [styles.arrowDown]: !isOpen },
          ])}
        />
      </div>

      <ul className={styles.options} role="listbox">
        {isOpen &&
          options.map((option, index) => (
            <li
              aria-selected={option.value === value.value}
              className={styles.item}
              data-option
              key={index}
              onClick={() => handleOptionClick(option)}
              onKeyDown={e => handleKeyPressList(e, option)}
              ref={liElementRefs[index]}
              role="option"
              tabIndex={0}
              value={option.value}
            >
              {option.label} {option.extraLabel}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SelectInput;
