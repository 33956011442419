import { useState, useEffect, ChangeEvent } from 'react';
import validate from '../utils/formValidationRules';

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  comment: string;
}

export interface FormErrors {
  firstName: { error: string; touched: boolean };
  lastName: { error: string; touched: boolean };
  email: { error: string; touched: boolean };
  phone: { error: string; touched: boolean };
}

const useForm = () => {
  const [preferedContact, setPreferedContact] = useState<'email' | 'phone'>(
    'email'
  );

  const [hasErrors, setHasErrors] = useState(true);

  const [errors, setErrors] = useState<FormErrors>({
    firstName: { error: '', touched: false },
    lastName: { error: '', touched: false },
    email: { error: '', touched: false },
    phone: { error: '', touched: false },
  });

  const [values, setValues] = useState<FormValues>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    comment: '',
  });

  const resetValues = () => {
    setValues({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      comment: '',
    });

    setErrors({
      firstName: { error: '', touched: false },
      lastName: { error: '', touched: false },
      email: { error: '', touched: false },
      phone: { error: '', touched: false },
    });

    setPreferedContact('email');
  };

  useEffect(() => {
    // Form is valid if all fields are touched and have valid entry
    let isFormValid = false;

    Object.values(errors).forEach(field => {
      if (field.error.length > 0 || !field.touched) {
        isFormValid = true;
        return;
      }
    });

    setHasErrors(isFormValid);
  }, [errors]);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setValues((values: any) => ({
      ...values,
      [event.target.id]: event.target.value,
    }));

    const validationResult = validate(
      event.target.id,
      event.target.value,
      preferedContact
    );

    setErrors({ ...errors, ...validationResult });
  };

  const handleBlur = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setValues((values: any) => ({
      ...values,
      [event.target.id]: event.target.value,
    }));

    const validationResult = validate(
      event.target.id,
      event.target.value,
      preferedContact
    );

    setErrors({ ...errors, ...validationResult });
  };

  const changePreferedContact = (option: 'email' | 'phone') => {
    setPreferedContact(option);

    const validationResult = validate(option, values[option], option);

    setErrors({ ...errors, ...validationResult });
  };

  return {
    changePreferedContact,
    errors,
    handleBlur,
    handleChange,
    hasErrors,
    preferedContact,
    resetValues,
    values,
  };
};

export default useForm;
