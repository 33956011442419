import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useState } from 'react';
import AnalyticsContext from '../../analytics/AnalyticsContext';
import {
  getDealerBlock,
  getEstimatorBlock,
  getGlobalBlock,
  getSectionBlock,
  getSelectedOffersBlock,
  getVehicleBlock,
} from '../../analytics/blocks';
import EventTypes from '../../analytics/EventTypes';
import useTrigger from '../../analytics/useTrigger';

import Cta from '../../components/Cta';
import Headline from '../../components/Headline';
import { sendHeight } from '../../services/PostMessageService';
import { useStore } from '../../stores';
import { changePageWithParams } from '../../utils/changePageWithParams';
import { getQueryParams } from '../../utils/getQueryParams';
import DisclaimerModal from './DisclaimerModal';
import FinancialDetails from './FinancialDetails';
import FinancialParams from './FinancialParams';
import { useDisclaimerModal } from './Hooks/useDisclaimerModal';
import SelectedVehicleDetails from './SelectedVehicleDetails';
import styles from './styles.module.scss';

const Estimator: FC = () => {
  const { closeDisclaimerModal, isDisclaimerModalOpen, openDisclaimerModal } =
    useDisclaimerModal();

  const {
    vehicleStore: {
      getVehicles,
      isApiInvoked,
      isUrlValid,
      selectedVehicle,
      selectedVehicleModel,
      selectedVehicles,
      setSelectedVehicle,
    },
    dealerStore: { selectedDealer },
    estimatorStore: {
      fetchOffers,
      financialParams,
      visibleOffers,
      isBestPriceCalculated,
    },
    globalStore: { tdaCode, setOnlySelectedVin },
  } = useStore();
  const trigger = useTrigger();
  const [isTriggered, setIsTriggered] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  const {
    backDisabled,
    dealerCode,
    model,
    onlySelectedVin,
    vin,
    year,
    zipcode,
  } = getQueryParams();

  useEffect(() => {
    if (onlySelectedVin === undefined) {
      setOnlySelectedVin(false);
    } else {
      setOnlySelectedVin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlySelectedVin]);

  useEffect(() => {
    if (model && year && dealerCode && !isApiInvoked) {
      getVehicles(dealerCode as string);

      const vehicle = selectedVehicles.find(vehicle => vehicle.vin === vin);

      if (vehicle) {
        setSelectedVehicle(vehicle);
      } else if (!vehicle && vin && selectedVehicles.length > 0) {
        changePageWithParams('/vehicleSelect', { dealerCode, zipcode }, true);
      }
    }
  }, [
    dealerCode,
    getVehicles,
    isApiInvoked,
    model,
    selectedVehicles,
    setSelectedVehicle,
    vin,
    year,
    zipcode,
  ]);

  useEffect(() => {
    sendHeight();
    if (zipcode && !isFetched && !!selectedVehicleModel.model) {
      fetchOffers();
      setIsFetched(true);
    }
  }, [fetchOffers, isFetched, selectedVehicleModel, zipcode]);

  if (!year || !model || !isUrlValid) {
    console.error(
      'This vehicle is no longer avaiable, please try with different one.'
    );
    changePageWithParams('/vehicleSelect', { dealerCode, zipcode }, true);
  }

  useEffect(() => {
    if (model && year && dealerCode && !isApiInvoked) {
      getVehicles(dealerCode as string);
    }
  }, [getVehicles, model, year, dealerCode, isApiInvoked]);

  const analyticsBlocks = useCallback(() => {
    const selectedOffers = visibleOffers.filter(offer => offer.isSelected);

    return {
      ...getGlobalBlock(zipcode as string, tdaCode),
      ...getSectionBlock('payment estimator form'),
      ...getEstimatorBlock(financialParams),
      ...getDealerBlock(selectedDealer),
      ...(selectedVehicle ? getVehicleBlock(selectedVehicle) : {}),
      ...getSelectedOffersBlock(selectedOffers),
    };
  }, [
    zipcode,
    financialParams,
    selectedDealer,
    selectedVehicle,
    visibleOffers,
    tdaCode,
  ]);

  useEffect(() => {
    if (selectedVehicle && !isTriggered && isBestPriceCalculated) {
      setTimeout(() => {
        trigger(EventTypes.KmPeShown, analyticsBlocks());
        setIsTriggered(true);
      }, 2500);
    }
  }, [
    selectedVehicle,
    trigger,
    isTriggered,
    isBestPriceCalculated,
    analyticsBlocks,
  ]);

  return (
    <>
      <AnalyticsContext blocks={analyticsBlocks}>
        <section className={styles.container}>
          {backDisabled === undefined && (
            <div className={styles.backButtonWrapper}>
              <Cta
                isRedColor
                isSecondary
                onClick={() =>
                  changePageWithParams(
                    'vehicleSelect',
                    { dealerCode: selectedDealer.code, zipcode: zipcode },
                    true
                  )
                }
              >
                Change vehicle
              </Cta>
            </div>
          )}
          <Headline
            dividedText={`${selectedVehicleModel.year} ${selectedVehicleModel.model}`}
            title="Payment Estimator"
          />
          <div className={styles.vehicleDetailsWrapper}>
            <SelectedVehicleDetails openDisclaimerModal={openDisclaimerModal} />
          </div>
          <FinancialParams openDisclaimerModal={openDisclaimerModal} />
          <FinancialDetails />
        </section>
      </AnalyticsContext>
      <DisclaimerModal
        isOpen={isDisclaimerModalOpen}
        onClose={closeDisclaimerModal}
      />
    </>
  );
};
export default observer(Estimator);
