import React, { ReactNode } from 'react';
import cc from 'classcat';

import Link from '../Link';
import styles from './styles.module.scss';

type ArrowVariant = 'toggleArrow' | 'downArrow' | 'backArrow';

interface CtaProps {
  arrowVariant?: ArrowVariant;
  children?: ReactNode;
  className?: string;
  href?: string;
  isActive?: boolean;
  isDark?: boolean;
  isPrimary?: boolean;
  isRedColor?: boolean;
  isSecondary?: boolean;
  isTransparent?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  title?: string;
  type?: 'submit';
}

const Cta: React.FC<CtaProps> = props => {
  const {
    arrowVariant,
    children,
    className,
    href,
    isActive = true,
    isDark,
    isPrimary,
    isRedColor = false,
    isSecondary,
    isTransparent,
    onClick = () => {},
    title,
    isDisabled,
    type = 'button',
  } = props;

  return (
    <>
      {href && (
        <Link
          className={cc([
            styles.cta,
            {
              [styles.dark]: isDark,
              [styles.inactive]: !isActive,
              [styles.isRedColor]: isRedColor,
              [styles.primary]: isPrimary,
              [styles.transparent]: isTransparent,
            },
            className,
          ])}
          href={href}
        >
          <span className={styles.ctaText}>{title}</span>
        </Link>
      )}

      {!href && (
        <button
          className={cc([
            styles.cta,
            {
              [styles.dark]: isDark,
              [styles.inactive]: !isActive,
              [styles.isRedColor]: isRedColor,
              [styles.primary]: isPrimary,
              [styles.secondary]: isSecondary,
              [styles.transparent]: isTransparent,
              [styles.disabled]: isDisabled,
            },
            className,
          ])}
          disabled={isDisabled}
          onClick={() => onClick()}
          type={type}
        >
          {title ? title : children}
          {arrowVariant && (
            <span
              className={arrowVariant === 'downArrow' ? styles.downArrow : ''}
            ></span>
          )}
        </button>
      )}
    </>
  );
};

export default Cta;
