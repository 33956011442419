// import ParentLocationService from '../../services/ParentLocationService';
import { isMobile } from '../hooks/useBreakpoints';

export const getCampaignCode = (_isMobile: boolean = isMobile()) => {
  let campaignCode;

  campaignCode = _isMobile
    ? process.env.REACT_APP_SLP_CAMPAIGN_CODE_MOBILE
    : process.env.REACT_APP_SLP_CAMPAIGN_CODE_DESKTOP;

  return campaignCode;
};
