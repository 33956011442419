import { AnalyticsBlock, AnalyticsBlockType } from './models';

export const combineBlocks = (
  blocks: AnalyticsBlockType | AnalyticsBlockType[],
  context?: AnalyticsBlock,
  props?: any
): AnalyticsBlock => {
  const blocksAsArray = Array.isArray(blocks) ? blocks : [blocks];

  const mappedBlocks = blocksAsArray
    .map(block =>
      typeof block === 'function' && typeof context !== 'undefined'
        ? block(context, props)
        : block
    )
    .filter(block => !!block);

  return Object.assign({}, ...mappedBlocks);
};

export const mergeBlocks = (...blocks: AnalyticsBlock[]): AnalyticsBlock => {
  const delimiter = '/';

  return blocks.reduce((mergedBlocks, block) => {
    for (const key of Object.keys(block)) {
      if (Object.prototype.hasOwnProperty.call(mergedBlocks, key)) {
        mergedBlocks[key] = `${mergedBlocks[key]}${delimiter}${block[key]}`;
      }
    }

    return mergedBlocks;
  });
};
