import React, { Component } from 'react';
import { DataLayerContext } from './DataLayerContext';
import { AnalyticsBlockType, ContextGenerator } from './models';
import { combineBlocks } from './utils';

export interface AnalyticsContextProps {
  blocks: AnalyticsBlockType | AnalyticsBlockType[];
}

class AnalyticsContext extends Component<AnalyticsContextProps> {
  private contextGenerator?: ContextGenerator;

  public render() {
    return (
      <DataLayerContext.Consumer>
        {this.renderWithContext}
      </DataLayerContext.Consumer>
    );
  }

  private contextProvider = () => {
    const contextGenerator = this.contextGenerator;
    const context = contextGenerator ? contextGenerator() : {};

    return Object.assign(
      context,
      combineBlocks(this.props.blocks, context, this.props)
    );
  };

  private renderWithContext = (contextGenerator: ContextGenerator) => {
    this.contextGenerator = contextGenerator;

    return (
      <DataLayerContext.Provider value={this.contextProvider}>
        {this.props.children}
      </DataLayerContext.Provider>
    );
  };
}

export default AnalyticsContext;
