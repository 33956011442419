const breakpoints = {
  tablet: 768,
  desktop: 960,
};

const getWindowWidth = () => {
  return Math.max(
    window.document.documentElement.clientWidth,
    window.innerWidth || 0
  );
};

export const isMobile = () => {
  const width = getWindowWidth();
  return width < breakpoints.tablet;
};
