import cookie from 'js-cookie';

let RANDOM_ID = '';

export const getOmnitureID = () => {
  const xactCookie = cookie.get('xact');
  if (xactCookie) {
    return xactCookie;
  } else {
    if (!RANDOM_ID) {
      RANDOM_ID = `${crypto.getRandomValues(new Uint8Array(1))[0] / 255}`;
    }
    return RANDOM_ID;
  }
};
