import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getDealerBlock,
  getEstimatorBlock,
  getGlobalBlock,
  getLinkBlock,
  getSectionBlock,
  getSelectedOffersBlock,
  getVehicleBlock,
} from '../../../analytics/blocks';
import EventTypes from '../../../analytics/EventTypes';
import useTrigger from '../../../analytics/useTrigger';
import Cta from '../../../components/Cta';
import Modal, { ModalProps } from '../../../components/Modal';
import TextInput from '../../../components/TextInput';
import { isMobile } from '../../../hooks/useBreakpoints';
import useForm from '../../../hooks/useForm';
import { SlpFormData } from '../../../models/Slp';
import { Serie } from '../../../models/Vehicle';
import { globalStore, useStore } from '../../../stores';
import { getNumberWithComa } from '../../../utils/getNumberWithComa';
import { getOfferLabel } from '../../../utils/getOfferLabel';
import { sanitizeString } from '../../../utils/sanitizeString';
import { submitContactDealerForm } from '../../../utils/submitContactDealerForm';
import styles from './styles.module.scss';

interface CadModalProps extends ModalProps {
  selectedVehicleSerie: Serie;
  openThankYouModal: () => void;
}

const CadModal: FC<CadModalProps> = ({
  onClose,
  isOpen,
  selectedVehicleSerie,
  openThankYouModal,
}) => {
  const { t } = useTranslation(['CadModal', 'ErrorMessages']);
  const trigger = useTrigger();

  const {
    values,
    errors,
    handleChange,
    resetValues,
    changePreferedContact,
    preferedContact,
    hasErrors,
    handleBlur,
  } = useForm();

  const [isFirstClick, setIsFirstClick] = useState<boolean>(true);

  const {
    dealerStore: { selectedDealer },
    vehicleStore: { selectedVehicle },
    estimatorStore: { financialParams, paymentResponse, visibleOffers },
    globalStore: { zipcode, tdaCode, setStatusBar },
  } = useStore();

  const selectedOffers = visibleOffers.filter(offer => offer.isSelected);

  let disclaimerLink = 'https://www.toyota.com/support/legal-terms.html';

  const getCombinedOffersString = () => {
    let combinedOffersString = '';

    visibleOffers.forEach(offer => {
      if (offer.isSelected) {
        combinedOffersString += '\n' + getOfferLabel(offer);
      }
    });

    return combinedOffersString;
  };

  const staticComment = `Estimated payment\n${Math.round(
    paymentResponse.monthlyPayment ?? 0
  )}/mo | ${paymentResponse.rate}% APR | $${getNumberWithComa(
    financialParams.downPayment
  )} | ${financialParams.term.label}\n\nEstimated Credit Score: ${
    financialParams.creditScore.label
  } (${
    financialParams.creditScore.extraLabel
  })\n\nCash Down: $${getNumberWithComa(
    financialParams.downPayment
  )}\n\nEstimated Trade-In: $${getNumberWithComa(
    financialParams.estimatedTradeIn
  )}\n\nZip Code: ${globalStore.zipcode}
  \nSelected offers: ${getCombinedOffersString()}`;

  const selectedTrim = {
    model: selectedVehicle?.model ?? '',
    purchaseType: 'buy',
    series: '',
    trim: selectedVehicle?.code ?? '',
    year: selectedVehicle?.year ?? '',
  };

  const submitContactForm = async (e: any) => {
    e.preventDefault();

    const sanitizedFormData = {
      dealer: selectedDealer,
      email: sanitizeString(values.email),
      firstname: sanitizeString(values.firstName),
      comments: sanitizeString(staticComment + '\n' + values.comment),
      lastname: sanitizeString(values.lastName),
      phone: values.phone,
      preference: preferedContact,
      vehicle: `${selectedVehicleSerie.year} ${selectedVehicleSerie.model}`,
      isMobile: isMobile(),
    } as SlpFormData;

    analyticsHandler(EventTypes.Click, 'Submit');

    try {
      await submitContactDealerForm(sanitizedFormData, selectedTrim);
      analyticsHandler(EventTypes.KmCadComplete);
      onClose && onClose();
      resetValues();
      openThankYouModal();
    } catch (e) {
      console.log(e);
      setStatusBar(true, t('ErrorMessages:serviceNotResponding'));
      analyticsHandler(EventTypes.KmFormSubmitServerSideError);
    }
  };

  const analyticsHandler = (event: string, linkText?: string) => {
    trigger(event, [
      getGlobalBlock(zipcode, tdaCode),
      getSectionBlock('pe contact dealer form'),
      getEstimatorBlock(financialParams),
      getDealerBlock(selectedDealer),
      selectedVehicle ? getVehicleBlock(selectedVehicle) : {},
      getSelectedOffersBlock(selectedOffers),
      linkText ? getLinkBlock({ href: '', text: linkText }) : {},
    ]);
  };

  useEffect(() => {
    if (isOpen) {
      analyticsHandler(EventTypes.KmCadShown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const triggerFirstClick = (inputName: string) => {
    if (isFirstClick) {
      analyticsHandler(EventTypes.KmCadStart, inputName);
      setIsFirstClick(false);
    }
  };

  return (
    <Modal
      onClose={() => {
        if (onClose !== undefined) onClose();
        resetValues();
      }}
      isOpen={isOpen}
    >
      <div className={styles.modalContainer}>
        <h1 className={styles.title}>{t('title')}</h1>
        <form action="" className={styles.form} onSubmit={submitContactForm}>
          <span className={styles.label}>{t('labels.selectVehicle')}</span>
          <div className={styles.selectedVehicle}>
            {`${selectedVehicleSerie.year} ${selectedVehicleSerie.model}`}
          </div>
          <span className={styles.label}>{t('labels.contactInfo')}</span>
          <div className={styles.inputWrapper}>
            <TextInput
              className={styles.textInput}
              error={errors.firstName.error.length > 0}
              id="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={() => triggerFirstClick('first name')}
              placeholder={t('placeholders.firstName')}
              value={values.firstName}
            />
            {errors.firstName && (
              <p className={styles.errorMessage}>{errors.firstName.error}</p>
            )}
          </div>
          <div className={styles.inputWrapper}>
            <TextInput
              className={styles.textInput}
              error={errors.lastName.error.length > 0}
              id="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={() => triggerFirstClick('last name')}
              placeholder={t('placeholders.lastName')}
              value={values.lastName}
            />
            {errors.lastName && (
              <p className={styles.errorMessage}>{errors.lastName.error}</p>
            )}
          </div>
          <span className={styles.label}>{t('labels.contactBy')}</span>
          <div className={styles.contactOptions}>
            <span>
              <input
                className={styles.radio}
                defaultChecked={preferedContact === 'email'}
                id="email"
                name="contactOption"
                onClick={() => {
                  changePreferedContact('email');
                  analyticsHandler(EventTypes.Click, 'email');
                }}
                type="radio"
                value="email"
              />
              <label
                className={styles.contactOption}
                htmlFor="email"
                tabIndex={0}
              >
                {t('labels.email')}
              </label>
            </span>
            <span>
              <input
                className={styles.radio}
                defaultChecked={preferedContact === 'phone'}
                id="phone"
                name="contactOption"
                onClick={() => {
                  changePreferedContact('phone');
                  analyticsHandler(EventTypes.Click, 'phone');
                }}
                type="radio"
                value="phone"
              />
              <label
                className={styles.contactOption}
                htmlFor="phone"
                tabIndex={0}
              >
                {t('labels.phone')}
              </label>
            </span>
          </div>
          <div className={styles.inputWrapper}>
            <TextInput
              className={styles.textInput}
              error={errors.email.error.length > 0}
              id="email"
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={() => triggerFirstClick('email')}
              placeholder={
                preferedContact === 'email'
                  ? t('placeholders.emailRequired')
                  : t('placeholders.email')
              }
              type="mail"
              value={values.email}
            />
            {errors.email && (
              <p className={styles.errorMessage}>{errors.email.error}</p>
            )}
          </div>
          <div className={styles.inputWrapper}>
            <TextInput
              className={styles.textInput}
              error={errors.phone.error.length > 0}
              id="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={() => triggerFirstClick('phone')}
              placeholder={
                preferedContact === 'phone'
                  ? t('placeholders.phoneRequired')
                  : t('placeholders.phone')
              }
              value={values.phone}
            />
            {errors.phone && (
              <p className={styles.errorMessage}>{errors.phone.error}</p>
            )}
          </div>
          <span className={styles.label}>{t('labels.leaveComment')}</span>

          <div className={styles.commentBox}>
            <div className={styles.staticComment}>{staticComment}</div>
            <textarea
              className={styles.comment}
              id="comment"
              maxLength={250}
              onChange={handleChange}
              onFocus={() => triggerFirstClick('comment')}
              placeholder={t('placeholders.comment')}
              rows={3}
              value={values.comment}
            />
          </div>
          <div className={styles.disclaimerWrapper}>
            By completing this form, you are submitting information to this
            dealer and they may be contacting you. To learn more about how this
            site handles your personal information, please click here to read
            privacy policy{' '}
            <Cta
              className={styles.disclaimerLink}
              isTransparent
              onClick={() => {
                window.open(disclaimerLink, '', 'noopener');
              }}
            >
              click here.
            </Cta>
          </div>
          <div className={styles.ctaContainer}>
            <div className={styles.ctaWrapper}>
              <Cta
                isPrimary
                title={t('submit')}
                type="submit"
                isDisabled={hasErrors}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default observer(CadModal);
