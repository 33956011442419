import { Offer } from '../models/Offer';

export const getOfferLabel = (offer: Offer) => {
  if (offer.offerType === 'TCUV APR') {
    return `${offer.apr?.tiers.tier[0].term[0].rate} % | ${offer.apr?.tiers.tier[0].term[0].duration} months`;
  } else if (offer.offerType === 'TCUV Cash') {
    return `$${offer.cashAmount} ${offer.title}`;
  } else {
    return `$${offer.cashAmount} ${offer.title}`;
  }
};
