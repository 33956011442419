import axios from 'axios';

import { OatServiceParams } from '../models/Offer';

const params: OatServiceParams = {
  brandId: 1,
  clientId: process.env.REACT_APP_OAT_CLIENT_ID,
};

export async function getOffers(zipcode: string, year: string, series: string) {
  return axios.get(
    `${process.env.REACT_APP_OAT_API}/v1/cuv/offers/${zipcode}`,
    {
      headers: { 'x-api-key': process.env.REACT_APP_OAT_API_KEY },
      params: { ...params, year, series },
    }
  );
}
