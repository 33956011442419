import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../stores';
import Headline from '../../components/Headline';
import Categories from './Categories ';
import Vehicles from './Vehicles';
import SelectInput from '../../components/SelectInput';
import { VehicleStoreObject } from '../../models/Vehicle';
import { Option } from '../../models/Option';
import styles from './styles.module.scss';
import {
  getGlobalBlock,
  getLinkBlock,
  getDealerBlock,
  getSectionBlock,
} from '../../analytics/blocks';
import useTrigger from '../../analytics/useTrigger';
import AnalyticsComponent from '../../analytics/AnalyticsComponent';
import EventTypes from '../../analytics/EventTypes';
import AnalyticsContext from '../../analytics/AnalyticsContext';
import { sendHeight } from '../../services/PostMessageService';

const VehicleSelect: FC = () => {
  const { t } = useTranslation(['Common', 'VehicleSelect']);
  const trigger = useTrigger();

  const {
    vehicleStore: {
      getVehicles,
      vehicles,
      selectedCategory,
      changeYear,
      selectedYear,
    },
    dealerStore: { selectedDealer },
    globalStore: { zipcode, tdaCode },
  } = useStore();

  const [yearOptions, setYearOptions] = useState<Option[]>(
    vehicles.carsminivans.years
  );

  useEffect(() => {
    if (selectedDealer.code) {
      getVehicles(selectedDealer.code);
    }
  }, [getVehicles, zipcode, selectedDealer, trigger]);

  useEffect(() => {
    sendHeight();
    const options =
      vehicles[selectedCategory as keyof VehicleStoreObject].years;

    setYearOptions(options);
  }, [vehicles, selectedCategory]);

  const analyticsBlocks = () => ({
    ...getGlobalBlock(zipcode, tdaCode),
    ...getDealerBlock(selectedDealer),
    ...getSectionBlock('model selector'),
  });

  const changeYearHandler = (yearOption: Option) => {
    if (selectedYear !== yearOption.value) {
      trigger(EventTypes.Click, [
        getLinkBlock({ href: '', text: yearOption.value }),
        analyticsBlocks(),
      ]);
      changeYear(yearOption.value);
      setTimeout(() => {
        sendHeight();
      });
    }
  };

  return (
    <AnalyticsContext blocks={analyticsBlocks}>
      <section className={styles.container}>
        <Headline title={t('title')} subTitle={t('VehicleSelect:subtitle')} />
        <div className={styles.categoriesWrapper}>
          <Categories />
        </div>
        <div className={styles.selectWrapper}>
          {yearOptions.length > 0 && (
            <SelectInput
              value={
                selectedYear
                  ? { value: selectedYear, label: selectedYear }
                  : yearOptions[0]
              }
              onClick={changeYearHandler}
              options={yearOptions}
            />
          )}
        </div>
        {vehicles[selectedCategory as keyof VehicleStoreObject].vehicles
          .length > 0 ? (
          <AnalyticsComponent>
            <Vehicles />
          </AnalyticsComponent>
        ) : (
          <div style={{ minHeight: 210 }} /> // for resizing iframe
        )}
      </section>
    </AnalyticsContext>
  );
};

export default observer(VehicleSelect);
