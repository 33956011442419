import { FC } from 'react';
import { observer } from 'mobx-react';

import Modal, { ModalProps } from '../../../components/Modal';
import { Offer } from '../../../models/Offer';
import styles from './styles.module.scss';

interface OfferDetailModalProps extends ModalProps {
  offer?: Offer;
}

const OfferDetailModal: FC<OfferDetailModalProps> = ({
  onClose,
  isOpen,
  offer,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <div className={styles.modalContainer}>
        <div className={styles.body}>
          {offer?.description && (
            <div className={styles.description}>{offer.description}</div>
          )}
          {offer && offer.disclaimers.disclaimer.length > 0 && (
            <div className={styles.disclaimerTitle}>Disclaimers</div>
          )}
          {offer?.disclaimers.disclaimer.map((disclaimer, index) => (
            <div className={styles.disclaimer} key={index}>
              <span className={styles.disclaimerIndex}>{index + 1}.</span>
              {disclaimer}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default observer(OfferDetailModal);
